import React, {useState, useRef, useMemo, useEffect, useCallback} from 'react';
import { useHistory } from "react-router-dom";
import Menu from './Menu';
import { useToasts } from 'react-toast-notifications';
import {useUser} from '../../contexts/user-context';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeletectProjectModal from './DeleteProjectModal';
import ChartModal from './ChartModal';
import CreateProject from './CreateProject';

const Project = ({project, goProject, handleOpenChart, handleDeleteProject, handleCloneProject}) => {
  const goProjectHandler = useCallback(() => {
    goProject(project.id);
  }, [project, goProject]);

  const removeClick = useCallback((e) => {
    e.stopPropagation();
    handleDeleteProject(project);
  }, [handleDeleteProject, project]);

  const chartClick = useCallback((e) => {
    e.stopPropagation();
    handleOpenChart(project);
  }, [handleOpenChart, project]);

  const cloneClick = useCallback((e) => {
    e.stopPropagation();
    handleCloneProject(project.id);
  }, [handleCloneProject, project]);

  return (
    <tr onClick={goProjectHandler}>
      <td>
    	<span>
	  {project.title}
	</span>
      </td>
      <td>{moment(project.modifiedAt).fromNow()}</td>
      <td>
    	{project.release && <span>Live</span>}
    	{!project.release && <span>Draft</span>}
      </td>
      <td>
    	{project.views && (
	  <div>
	    {project.views}
	    <FontAwesomeIcon className="chart-icon" icon='chart-bar' onClick={chartClick}/>
	  </div>
	)}
    	{!project.views && (
	  <div>--</div>
	)}
      </td>
      <td>
	<FontAwesomeIcon title="clone" className="copy-icon" icon='copy' onClick={cloneClick}/>
      </td>
      <td>
        {!project.subscribed && (
	  <FontAwesomeIcon className="trash-icon" icon='trash' onClick={removeClick}/>
	)}
      </td>
    </tr>
  )
}

const Dashboard = () => {
  const deleteProjectModalRef = useRef(null);
  const chartModalRef = useRef(null);
  const history = useHistory();
  const user = useUser();
  const [showCreate, setShowCreate] = useState(false);

  const { addToast } = useToasts();
  const {deleteProject, loadProjects, loadProjectAnalytics, loadMe, cloneProject} = user;

  const projects = useMemo(() => {
    return user.projects || [];
  }, [user.projects]);

  const projectAnalytics = useMemo(() => {
    return user.projectAnalytics || [];
  }, [user.projectAnalytics]);

  useEffect(() => {
    loadProjectAnalytics();
    loadProjects();
    loadMe();
  }, [loadProjectAnalytics, loadProjects, loadMe]);

  const goProject = useCallback((projectId) => {
    history.push("/project/" + projectId);
  }, [history]);

  const addProjectClick = useCallback(async () => {
    setShowCreate(!showCreate);
  }, [setShowCreate, showCreate]);

  const openDeleteProject = useCallback(async (project) => {
    deleteProjectModalRef.current.open({project});
  }, []);

  const handleCloneProject = useCallback(async (projectId) => {
    const result = await cloneProject(projectId);
    if (result) {
      addToast('Clone Project successful', { appearance: 'success', autoDismiss: true });
      loadProjects();
    } else {
      addToast('Clone project failed', { appearance: 'error', autoDismiss: true });
    }
  }, [addToast, cloneProject, loadProjects]);

  const handleDeleteProject = useCallback(async (projectId) => {
    const {error} = await deleteProject(projectId);
    if (error) {
      addToast('Delete Project Failed', { appearance: 'error', autoDismiss: true });
    } else {
      addToast('Delete Project successful', { appearance: 'success', autoDismiss: true });
    }
    deleteProjectModalRef.current.close();
  }, [addToast, deleteProject]);

  const openChart = useCallback((project) => {
    const pa = projectAnalytics.find((pa) => pa.id === project.id);
    chartModalRef.current.open({analytic: pa});
  }, [projectAnalytics]);

  const sortedProjects = useMemo(() => {
    const sorted = [...projects].sort((p1, p2) => {
      return p1.modifiedAt < p2.modifiedAt ? 1 : -1;
    });
    const ret = sorted.map((p) => {
      const pa = projectAnalytics.find((pa) => pa.id === p.id);
      return Object.assign({}, p, {views: pa?.views.count});
    });
    return ret;
  }, [projects, projectAnalytics]);

  const handleCloseCreateProject = useCallback(() => {
    setShowCreate(false);
  }, [setShowCreate]);

  return (
    <div className="dashboard">
      <DeletectProjectModal ref={deleteProjectModalRef} handleDelete={handleDeleteProject} />
      <ChartModal ref={chartModalRef} />
      <Menu />
    
      <div className="main">
        {(user.me && !user.me.email) && (
	  <div className="warning">
	    This is a trial account, and your projects might vanish. If you want to keep them, please sign up.
	  </div>
	)}

        <div className="toolbar">
    	  <div className="left">
    	    Projects
	  </div>
    	  <div className="right">
    	    <button className="button primary" onClick={addProjectClick}>Create Project...</button>
	  </div>
	</div>

        {showCreate && <CreateProject handleClose={handleCloseCreateProject} goProject={goProject}/>}

    	<table>
    	  <thead>
    	    <tr>
    	      <th>Name</th>
    	      <th>Last Updated</th>
    	      <th>Status</th>
    	      <th>#Views</th>
    	      <th width="20px"></th>
    	      <th width="20px"></th>
	    </tr>
	  </thead>
    	  <tbody>
	    {sortedProjects.map((project) => (
	      <Project key={project.id} project={project} goProject={goProject} handleOpenChart={openChart} handleDeleteProject={openDeleteProject} handleCloneProject={handleCloneProject}/>
	    ))}
	  </tbody>
	</table>
      </div>
    </div>
  )
}

export default Dashboard;
