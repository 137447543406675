import React, {useState, useCallback, forwardRef} from 'react';
import Modal from '../common/Modal';
import { useToasts } from 'react-toast-notifications';
import { useProject} from '../../contexts/project-context';

const SettingsModal = (props, ref) => {
  const [detectionMode, setDetectionMode] = useState();
  const {project, saveProject} = useProject();
  const { addToast } = useToasts();

  const detectionModeChange = useCallback((value) => {
    setDetectionMode(value);
  }, [setDetectionMode]);

  const saveClick = useCallback(async (e) => {
    const settings = {detectionMode};
    const {error} = await saveProject({settings});
    if (error) {
      addToast('Failed to update settings', { appearance: 'error', autoDismiss: true });
    } else {
      addToast('Project settings updated', { appearance: 'success', autoDismiss: true });
    }
    ref.current.close();
  }, [detectionMode, addToast, ref, saveProject]);

  const onOpen = useCallback(() => {
    const detectionMode = project.settings && project.settings.detectionMode? project.settings.detectionMode: "single";
    setDetectionMode(detectionMode);
  }, [project, setDetectionMode]);

  return (
    <Modal {...props} ref={ref} className="project-settings-modal small" header={"Project Settings"} onOpen={onOpen} >
      <div className="main">
        <div>
	  Detection Mode

	  <label>
	    <input
	      type="radio"
	      value="single"
    	      onChange={() => {detectionModeChange('single')}}
	      checked={detectionMode === "single"}/>
	    <span>Single</span>
	  </label>

	  <label>
	    <input
	      type="radio"
	      value="multiple"
    	      onChange={() => {detectionModeChange('multiple')}}
	      checked={detectionMode === "multiple"}/>
	    <span>Multiple</span>
	  </label>
    

	</div>

	<div>
	  <button className="button primary" onClick={saveClick}>Save</button>
	</div>
      </div>
    </Modal>
  )
}
export default forwardRef(SettingsModal);
