import React, {useEffect, useCallback} from 'react';
import {useUser} from '../../contexts/user-context';
import templateBlankImage from '../../images/template-blank.png';

const CreateProject = ({handleClose, goProject}) => {
  const {templateProjects, loadTemplateProjects, createProject, cloneProject} = useUser();
  
  useEffect(() => {
    loadTemplateProjects();
  }, [loadTemplateProjects]);

  const cloneProjectClick = useCallback(async (projectId) => {
    const newProjectId = await cloneProject(projectId);
    if (newProjectId) {
      goProject(newProjectId);
    }
  }, [cloneProject, goProject]);

  const blankProjectClick = useCallback(async () => {
    const projectId = await createProject();
    if (projectId) {
      goProject(projectId);
    }
  }, [createProject, goProject]);

  return (
    <div className="create-project-section">
      <div className="instruction">
    	Create a blank project or start from a template
      </div>
      <div className="close clickable" onClick={handleClose}>
        &times;
      </div>
    
      <div className="items">
    	<div className="item">
          <div className="thumbnail">
	    <img alt="blank-project" src={templateBlankImage} />
	  </div>
    	  <div className="details">
	    <div className="title">Blank Project</div>
	    <div className="description">
	    </div>
	    <div className="buttons">
	      <button className="button primary ga-event" data-ga-event="create_project" onClick={blankProjectClick}>Create</button>
	    </div>
	  </div>
	</div>
        {templateProjects.map((templateProject) => (
	  <div key={templateProject.projectId} className="item">
	    <div className="thumbnail">
	      <img alt="template-project" src={templateProject.thumbnail} />
	    </div>
	    <div className="details">
	      <div className="title">{templateProject.title}</div>
	      <div className="description">
		<ul>
		  {templateProject.descriptions.map((d, i) => (
		    <li key={i}>{d}</li>
		  ))}
		</ul>
	      </div>
	      <div className="buttons">
		<button className="button primary ga-event" data-ga-event="create_project" onClick={() => cloneProjectClick(templateProject.projectId)}>Clone</button>
	      </div>
	    </div>
	  </div>
	))}
      </div>
    </div>
  )
}

export default CreateProject;
