import React, {useCallback, useState, forwardRef} from 'react';
import Modal from '../common/Modal';
import { useProject} from '../../contexts/project-context';
import SubmitButton from '../common/SubmitButton';

const SERVE_URL = process.env.REACT_APP_SERVE_URL;

const SetupReleaseModal = (props, ref) => {
  const [key, setKey] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const {setProjectKey} = useProject();

  const {onSetupSuccess} = props;

  const keyChange = useCallback((e) => {
    setKey(e.target.value);
  }, [setKey]);

  const onOpen = useCallback(() => {
    setKey('');
  }, [setKey]);

  const submit = useCallback(async (e) => {
    e.preventDefault();


    const regex = new RegExp("^(?=.*[a-z])(?:[a-z0-9]|-(?!-)){3,15}$");
    if (!regex.test(key) || key[0] === '-' || key[key.length-1] === '-') {
      setError("Invalid URL");
      return;
    }

    setError("");
    setLoading(true);
    const {error} = await setProjectKey({key})
    setLoading(false);
    if (error) {
      setError(error);
      return;
    }
    onSetupSuccess();
  }, [key, setProjectKey, setError, onSetupSuccess]);

  return (
    <Modal {...props} ref={ref} className="setup-release-modal small" header={"Release Setup"} onOpen={onOpen}>
      <div className="main">
	<div className="left">
	  <div className="title">
    	    Before release, you need to choose a unique URL for your application.
    	    <br/> <strong>This will be same for all your future releases.</strong>
	  </div>
	  <div className="notes">
    	    <ul>
    	      <li>cannot be changed later</li>
	      <li>3 - 15 lowercase characters, or hyphen</li>
	    </ul>
	  </div>
    	  <form className="form common-form">
	    <div className="field key-field">
	      <span className="prefix">{SERVE_URL}/</span>
    	      <input type="text" value={key} onChange={keyChange}/> 
	    </div>
	    <div className="field">
	      <SubmitButton className="button primary" onClick={submit} text="Submit" loading={loading} />
	    </div>

    	    {error &&
	      <div className="field error">
		{error}
	      </div>
	    }
	  </form>
	</div>
      </div>
    </Modal>
  )
}
export default forwardRef(SetupReleaseModal);
