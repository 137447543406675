import React, {useRef, useCallback} from 'react';
import {useEditor} from '../../contexts/editor-context';
import AssetModal from '../assetModal/AssetModal';

const TargetImagePropertiesPanel = ({selectedTarget, selectedItem}) => {
  const {editor} = useEditor();
  const assetModalRef = useRef(null);

  const selectAssetHandler = useCallback(async (asset) => {
    if (asset.type !== 'image') return;
    await editor.updateImageTarget(selectedTarget, asset);
    assetModalRef.current.close();
  }, [editor, selectedTarget]);

  const changeClick = useCallback(() => {
    assetModalRef.current.open();
  }, []);

  return (
    <div>
      <AssetModal ref={assetModalRef} selectType="TARGET" selectAssetHandler={selectAssetHandler}/>

      <div className="title">Target Image</div>
      <img alt="target" className="target-image" src={selectedItem.image.src} />

      <button className="target-image-change-button button primary" onClick={changeClick}>Change</button>
    </div>
  )
}

export default TargetImagePropertiesPanel;
