import React, {useState, useRef, useCallback} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EditableText = ({originalValue, onConfirm}) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState(null);

  const onChange = useCallback((e) => {
    setValue(e.target.value);
  }, [setValue]);

  const editClick = useCallback(() => {
    setValue(originalValue);
    setTimeout(() => {
      if (inputRef.current) inputRef.current.focus();
    }, 50);
  }, [setValue, originalValue]);

  const cancelClick = useCallback(() => {
    setValue(null);
  }, [setValue]);

  const confirmClick = useCallback(async (e) => {
    onConfirm(value);
    setValue(null);
  }, [value, setValue, onConfirm]);

  const onKeyDown = useCallback((e) => {
    if (e.key === 'Escape') cancelClick();
    if (e.key === 'Enter') confirmClick();
  }, [cancelClick, confirmClick]);

  return (
    <div className="common-editable-text">
      {value===null && <div className="current">{originalValue}</div>}
      {value===null && <div><FontAwesomeIcon className="clickable" icon="edit" onClick={editClick} /></div>}

      {value!==null && (
	<div className="input-wrapper">
	  <div><input ref={inputRef} type="text" value={value} onChange={onChange} onKeyDown={onKeyDown}/></div>
	  <div><FontAwesomeIcon className="clickable" icon="check-circle" onClick={confirmClick} /></div>
	  <div><FontAwesomeIcon className="clickable" icon="times-circle" onClick={cancelClick} /></div>
	</div>
      )}
    </div>
  )
}

export default EditableText;
