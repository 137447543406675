import {THREE, RoomEnvironment, EditorControls, TransformControls} from 'pictarize-lib';
import { OrientationHelper } from './OrientationHelper';

const isDevelopment = process.env.NODE_ENV === 'development';

class Viewport {
  setup({editor, container}) {
    this.uuid = THREE.MathUtils.generateUUID();
    this.editor = editor;
    this.container = container;

    this.camera = this._setupCamera();

    this.editorControls = new EditorControls(this.camera, container);
    this.transformControls = new TransformControls(this.camera, container);
    this.transformControls.space = 'local';
    this.orientationHelper = new OrientationHelper(this.camera, container, this.editorControls);

    this.selectionBox = this._setupSelectionBox();
    this.sceneHelpers = this._setupSceneHelpers({transformControls: this.transformControls, selectionBox: this.selectionBox});

    this.renderer = this._setupRenderer();

    this._setupSelectionEvents();
    this._setupControls();
    this._setupSignalsListener();

    this._performanceDisplay = this._setupPerformanceDisplay();

    this.resizeHandler = this._resizeHandler.bind(this);
    window.addEventListener('resize', this.resizeHandler);

    this.resizeHandler();
    this.render();

  }

  dispose() {
    window.removeEventListener('resize', this.resizeHandler);
    if(this.renderer) this.renderer.dispose();
  }

  _setupPerformanceDisplay() {
    const {container} = this;
    const div = document.createElement("div");
    div.classList.add("performance");
    container.appendChild(div);
    return div;
  }

  _resizeHandler() {
    const {renderer, camera, container} = this;
    renderer.setViewport(0, 0, container.offsetWidth, container.offsetHeight);
    renderer.setSize(container.offsetWidth, container.offsetHeight, false);
    camera.aspect = container.offsetWidth / container.offsetHeight;
    camera.updateProjectionMatrix();
    this.render();
  }
  
  _setupRenderer() {
    const {editor, container, orientationHelper} = this;
    const renderer = new THREE.WebGLRenderer({antialias: true, alpha: true });
    renderer.autoClear = false;
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.setClearColor( 0xebeced );
    renderer.setPixelRatio( window.devicePixelRatio );
    container.appendChild(renderer.domElement);
    renderer.domElement.style.position = 'absolute';
    renderer.domElement.style.top = 0;
    renderer.domElement.style.zIndex = 2;

    const pmremGenerator = new THREE.PMREMGenerator( renderer );
    pmremGenerator.compileEquirectangularShader();
    
    editor.scene.environment = pmremGenerator.fromScene( new RoomEnvironment(), 0.04 ).texture;
    pmremGenerator.dispose();
    
    const clock = new THREE.Clock(); // only used for animations
    renderer.setAnimationLoop(() => {
      const delta = clock.getDelta();
      if (orientationHelper.animating === true) {
	orientationHelper.update(delta);
	this.render();
      }
    });

    return renderer;
  }

  render() {
    const startTime = performance.now();
    const {container, orientationHelper, renderer, camera, sceneHelpers, editor} = this;
    //if (!editor.selectedTarget) return;

    const {scene} = editor;
    renderer.clear();
    renderer.setViewport(0, 0, container.offsetWidth, container.offsetHeight);
    renderer.render(scene, camera);
    renderer.render(sceneHelpers, camera);
    orientationHelper.render(renderer);

    const endTime = performance.now();
    const {geometries, textures} = this.renderer.info.memory;

    if (isDevelopment) {
      this._performanceDisplay.innerHTML = (endTime - startTime).toFixed(2) + ". geometries: " + geometries + ", textures: " + textures;
    }
  }

  _setupSignalsListener() {
    const {transformControls, selectionBox, editor} = this;
    const {signals} = editor;

    signals.selectedItemPropertiesChanged.add(() => {
      if (!editor.selectedItem) return;
      const mesh = editor.selectedItem.mesh;
      selectionBox.setFromObject(mesh);
      this.render();
    });

    signals.selectedTargetChanged.add(() => {
      this.render();
    });

    signals.transformModeChanged.add((mode) => {
      transformControls.setMode(mode);
    });

    signals.selectedItemChanged.add((selectedItem) => {
      transformControls.detach();

      if (!selectedItem) {
	selectionBox.visible = false;
	this.render();
	return;
      }
      if (selectedItem.isContent) {
	transformControls.attach(selectedItem.mesh);
      }
      selectionBox.setFromObject(selectedItem.mesh);
      selectionBox.visible = true;
      this.render();
    });

    signals.itemRemoved.add((item) => {
      this.render();
    });
  }

  _setupSelectionEvents() {
    const {editorControls, container, editor, camera} = this;

    let downPos;
    const mouseDownHandler = (e) => {
      downPos = [e.clientX, e.clientY];
    };
    const mouseUpHandler = (e) => {
      const {selectedTarget} = editor;
      if(!selectedTarget) return;

      if (e.clientX !== downPos[0] || e.clientY !== downPos[1]) return;
      const item = this._getIntersectObject(selectedTarget, camera, container, e.clientX, e.clientY);
      editor.selectItem(item);
    };
    const doubleClickHandler = (e) => {
      const {selectedTarget} = editor;
      if(!selectedTarget) return;

      const item = this._getIntersectObject(selectedTarget, camera, container, e.clientX, e.clientY);
      if (!item) return;
      editorControls.focus(item.mesh);
      this.render();
    };

    container.addEventListener('mouseup', mouseUpHandler);
    container.addEventListener('mousedown', mouseDownHandler);
    container.addEventListener('dblclick', doubleClickHandler);
  }

  _setupControls() {
    const {editor, selectionBox, editorControls, transformControls} = this;
    editorControls.addEventListener('change', () => this.render());

    const changeListener = () => {
      selectionBox.setFromObject(transformControls.object);
      editor.onViewportUpdatedContentProperty(false);
      this.render();
    }
    const mouseDownListener = () => {
      editorControls.enabled = false;
    }
    const mouseUpListener = () => {
      editor.onViewportUpdatedContentProperty(true);
      editorControls.enabled = true;
    }
    transformControls.addEventListener('change', changeListener);
    transformControls.addEventListener('mouseDown', mouseDownListener);
    transformControls.addEventListener('mouseUp', mouseUpListener);
  }

  _setupSceneHelpers({transformControls, selectionBox}) {
    const grid1 = new THREE.GridHelper( 3000, 30, 0x000000 );
    grid1.material.color.setHex( 0x1976d2 );
    grid1.material.transparent = true;
    grid1.material.opacity = 0.1;
    grid1.material.vertexColors = false;
    const grid2 = new THREE.GridHelper( 3000, 6, 0x222222 );
    grid2.material.color.setHex( 0x004ba0 );
    grid2.material.transparent = true;
    grid2.material.opacity = 0.5;
    grid2.material.needsUpdate = true;

    const grid = new THREE.Group();
    grid.add(grid1);
    grid.add(grid2);

    const sceneHelpers = new THREE.Scene();
    sceneHelpers.add(grid);
    sceneHelpers.add(selectionBox);
    sceneHelpers.add(transformControls);

    return sceneHelpers;
  }

  _setupSelectionBox() {
    const selectionBox = new THREE.BoxHelper();
    selectionBox.material.color.set(0x222222);
    selectionBox.material.depthTest = false;
    selectionBox.material.transparent = true;
    selectionBox.visible = false;
    return selectionBox;
  }

  _setupCamera() {
    const camera = new THREE.PerspectiveCamera(50, 1, 1, 100000);
    camera.position.set(0, 1000, 2000);
    camera.lookAt(new THREE.Vector3());
    return camera;
  }

  _getIntersectObject(target, camera, container, x, y) {
    const {subScene: scene, contents, imageTarget} = target;

    const rect = container.getBoundingClientRect();
    const point = [ ( x - rect.left ) / rect.width, ( y - rect.top ) / rect.height ];

    const mouse = new THREE.Vector2(point[0]*2-1, -(point[1]*2) + 1);
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);
    
    const intersects = raycaster.intersectObjects(scene.children, true);
    if (intersects.length === 0) return null;

    let o = intersects[0].object; 
    while (o.parent && o.parent !== scene) {
      o = o.parent;
    }

    if (o === imageTarget.mesh) return imageTarget;
    return contents.find((content) => content.mesh === o);
  }
}

export default Viewport;
