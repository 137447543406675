import React, {useCallback, forwardRef, useRef} from 'react';
import Modal from '../common/Modal';
import {Player} from 'pictarize-lib';
import { useToasts } from 'react-toast-notifications';
import ipadBorder from '../../images/ipad-border.png';

const PlayerModal = (props, ref) => {
  const playerRef = useRef(null);
  const containerRef = useRef(null);
  const { addToast } = useToasts();

  const onClose = useCallback(() => {
    if (playerRef.current) {
      playerRef.current.dispose();
    }
  }, []);

  const onOpen = useCallback(async (params) => {
    const target = params.target;
    const player = new Player(containerRef.current);
    const errorScripts = await player.init([target], true);
    await player.dummyTrigger();
    player.resizeUI();
    window.requestAnimationFrame(() => player.resizeUI());
    //player.initContainer(containerRef.current);
    
    /*
    // Testing region capture
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    ctx.beginPath();
    ctx.rect(40, 40, 150, 100);
    ctx.fillStyle = "blue";
    ctx.fill();
    const textureImage = canvas.toDataURL("image/png")
    player.startTarget(0, textureImage);
    */
    
    player.startTarget(0);
    player.onCustomScriptError = (msg) => {
      addToast('error running your custom script: ' + msg, { appearance: 'error', autoDismiss: true });
      player.stopTarget(0);
    }
    playerRef.current = player;

    if (errorScripts.length > 0) {
      const error = 'scripts error for: ' + errorScripts.join(', ');
      addToast(error, { appearance: 'error', autoDismiss: true });
    }
  }, [addToast]);

  return (
    <Modal {...props} ref={ref} className="player-modal" header={""} onOpen={onOpen} onClose={onClose}>
      <div className="outer-container">
	<img alt="ipad-border" className="ipad-border" src={ipadBorder} />
	<div className="container" ref={containerRef}>
	</div>
      </div>
    </Modal>
  )
}

export default forwardRef(PlayerModal);
