import React, {useRef, useCallback, useEffect, useState} from 'react';
import {computeProjectSizes, readableFileSize} from '../../libs/file';
import {useEditor} from '../../contexts/editor-context';
import ProjectSizeModal from './ProjectSizeModal';

const ProjectSize = () => {
  const projectSizeModalRef = useRef(null);
  const [size, setSize] = useState(0);
  const {editor} = useEditor();

  const openModal = useCallback(() => {
    projectSizeModalRef.current.open();
  }, []);

  useEffect(() => {
    const recompute = () => {
      const {totalSize} = computeProjectSizes(editor.targets);
      setSize(totalSize);
    }

    editor.signals.targetsChanged.add(recompute);
    editor.signals.targetChanged.add(recompute);
    return () => {
      editor.signals.targetsChanged.remove(recompute);
      editor.signals.targetChanged.remove(recompute);
    }
  }, [editor, setSize]);

  return (
    <div className="project-size">
      <ProjectSizeModal ref={projectSizeModalRef} />
      <div className={"link clickable"} onClick={openModal}>
	project size: {readableFileSize(size)}
      </div>
    </div>
  )
}

export default ProjectSize;
