import React, {useRef, useMemo, forwardRef, useCallback, useState} from 'react';
import {useProject} from '../../contexts/project-context';
import { useEditor} from '../../contexts/editor-context';
import {useUser} from '../../contexts/user-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToasts } from 'react-toast-notifications';
import Modal from '../common/Modal';
import SubmitButton from '../common/SubmitButton';
import {generateBuildZip, compileTargets, downloadSelfHost} from '../../libs/builder';
import {computeProjectSizes, readableFileSize} from '../../libs/file';
import SetupReleaseModal from './SetupReleaseModal';
import OpenAppModal from './OpenAppModal';
import UpgradeModal from './UpgradeModal';
import moment from 'moment';

const SERVE_URL = process.env.REACT_APP_SERVE_URL;

const BuildItem = ({isRelease, build, openBuildPreview, handleDelete, handleRelease, handleDownload, isProMember}) => {
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const releaseHandler = useCallback(async () => {
    setLoading(true);
    await handleRelease(build);
    setLoading(false);
  }, [build, setLoading, handleRelease]);

  const deleteHandler = useCallback(async () => {
    setLoadingDelete(true);
    await handleDelete(build);
    setLoadingDelete(false);
  }, [build, setLoadingDelete, handleDelete]);

  const downloadHandler = useCallback(async () => {
    setLoadingDownload(true);
    await handleDownload(build);
    setLoadingDownload(false);
  }, [build, setLoadingDownload, handleDownload]);

  const openClick = useCallback(() => {
    openBuildPreview(build);
  }, [openBuildPreview, build]);

  return (
    <tr className="item">
      <td>
	{build.buildIndex}
      </td>
      <td>
	{readableFileSize(build.size)}
      </td>
      <td>
	{moment(build.createdAt).fromNow()}
      </td>
      <td className="shrink">
    	<button className="button primary" onClick={openClick}>Preview</button>
      </td>
      <td className="shrink">
    	{isRelease && <strong>This is the current Live Version</strong>}
    	{!isRelease && <SubmitButton className="button green" onClick={releaseHandler} text="Release" loading={loading} />}
    	{!isRelease && <SubmitButton className="button warning" onClick={deleteHandler} text="Delete" loading={loadingDelete} />}
      </td>
      {isProMember && 
	<td className="shrink">
	  {<SubmitButton className="button green" onClick={downloadHandler} text="Download" loading={loadingDownload} />}
	</td>
      }
    </tr>
  )
}

const PublishModal = (props, ref) => {
  const upgradeModalRef = useRef(null);
  const setupReleaseModalRef = useRef(null);
  const openAppModalRef = useRef(null);
  const [buildStatus, setBuildStatus] = useState(null);
  const {project, uploadBuild, unreleaseProject, releaseProject, deleteProjectBuild} = useProject();
  const {editor} = useEditor();
  const {me} = useUser();
  const { addToast } = useToasts();

  //console.log("me", me);

  const isProMember = useMemo(() => {
    return me && me.proMemberExpiredAt && moment(me.proMemberExpiredAt).isAfter(moment());
  }, [me]);

  const isPaidPlan = useMemo(() => {
    //return project.plan && moment(project.plan.expiredAt).isAfter(moment('2040-01-01'));
    //return project.plan && moment(project.plan.expiredAt).isAfter(moment());
    return !!project.stripeSubscription;
  }, [project]);

  const handleDelete = useCallback(async (build) => {
    const {error} = await deleteProjectBuild({buildId: build.buildId});
    if (error) {
      addToast('Delete Build Failed', { appearance: 'error', autoDismiss: true });
    } else {
      addToast('Delete Build successful', { appearance: 'success', autoDismiss: true });
    }
  }, [addToast, deleteProjectBuild]);

  const handleRelease = useCallback(async (build) => {
    if (!isPaidPlan) {
      addToast('You need to upgrade the project to create production release', { appearance: 'error', autoDismiss: true });
      return;
    }
    if (!project.key) {
      setupReleaseModalRef.current.open();
      return;
    }
    const {error} = await releaseProject({buildId: build.buildId});
    if (error) {
      addToast('Release Failed', { appearance: 'error', autoDismiss: true });
    } else {
      addToast('Release successful!', { appearance: 'success', autoDismiss: true });
    }
  }, [isPaidPlan, addToast, project, releaseProject]);

  const handleDownload = useCallback(async (build) => {
    //console.log("handle download", build);
    await downloadSelfHost(build);
  }, [downloadSelfHost]);

  const onSetupSuccess = useCallback(() => {
    addToast('Setup Success. Please release again!', { appearance: 'success', autoDismiss: true });
    setupReleaseModalRef.current.close();
  }, [addToast]);

  const liveURL = useMemo(() => {
    if (!project.release) return null;
    const split = SERVE_URL.split("/");
    return split[0] + "//" + project.key + "." + split[2];
  }, [project]);
  
  const handleBuild = useCallback(async () => {
    setBuildStatus('compiling targets...');
    const mindBlob = await compileTargets(editor.targets, (progress) => {
      setBuildStatus(`compiling targets (${progress.toFixed(2)}%)`);
    });

    setBuildStatus('building project...');
    const zip = await generateBuildZip(project.settings, editor.targets, mindBlob);

    const {error} = await uploadBuild({zip});
    if (error) {
      addToast(error, { appearance: 'error', autoDismiss: true });
    } else {
      addToast('Build Successful. You may preview or release the build now!', { appearance: 'success', autoDismiss: true });
    }
    setBuildStatus(null);
  }, [project, addToast, setBuildStatus, editor, uploadBuild]);

  const handleUnrelease = useCallback(async () => {
    const result = await unreleaseProject();
    if (!result) {
      addToast('Failed to un-released.', { appearance: 'error', autoDismiss: true });
    } else {
      addToast('Project is un-released.', { appearance: 'success', autoDismiss: true });
    }
  }, [addToast, unreleaseProject]);

  const projectBuilds = useMemo(() => {
    const builds = project.builds && project.builds.map((build, index) => {
      return Object.assign({}, build, {buildIndex: index+1});
    });
    builds.reverse();
    return builds;
  }, [project]);

  const openBuildPreview = useCallback((build) => {
    openAppModalRef.current.open({build});
  }, []);

  const openUpgrade = useCallback(() => {
    if (!me.email) {
      addToast('Please register an account first', { appearance: 'error', autoDismiss: true });
      return
    }
    upgradeModalRef.current.open();
  }, [addToast, me]);

  const openLiveDetails = useCallback(() => {
    openAppModalRef.current.open();
  }, []);

  const projectSizeStatus = useMemo(() => {
    const result = computeProjectSizes(editor.targets);
    return result;
  }, [editor]);

  const sizeExceeded = useMemo(() => {
    return projectSizeStatus.healthStatus === 'critical';
  }, [projectSizeStatus]);

  return (
    <Modal {...props} ref={ref} className="publish-modal" header={"Publish"} forbidClose={!!buildStatus}>
      <SetupReleaseModal ref={setupReleaseModalRef} onSetupSuccess={onSetupSuccess}/>
      <OpenAppModal ref={openAppModalRef}/> 
      <UpgradeModal ref={upgradeModalRef}/> 
      <div>
    	{!isPaidPlan &&
	  <div className="notice">
	    <div className="left">
	      Upgrade your project for production release
	      <button className="button primary ga-event" data-ga-event="open_upgrade_project" onClick={openUpgrade}>Upgrade...</button>
	    </div>
	  </div>
	}

    	{isPaidPlan && !liveURL &&
	  <div className="notice">
	  There is currently no release yet. To release your app, Build and then Release
	  </div>
	}

        {isPaidPlan && liveURL && 
	  <div className="notice">
	    <div className="left">
	      Your app is live at <a href={liveURL} target="_blank" rel="noreferrer">{liveURL}</a>
	      <button className="button primary live-details-button" onClick={openLiveDetails}>View</button>
	    </div>
	    <div className="right">
	      <button className="button warning" onClick={handleUnrelease}>Un-release</button>
	    </div>
	  </div>
	}

	<div className="toolbar">
	  <div className="left">
            {!buildStatus && (
	      <div>
		<button className="button green ga-event" data-ga-event="build_project" onClick={handleBuild} disabled={sizeExceeded}>
		  <FontAwesomeIcon icon="wrench" size="xs" />
		  Build
		</button>
		{sizeExceeded && (
		  <span className="error">Your project size is {readableFileSize(projectSizeStatus.totalSize)}, which exceeded the 20Mb limits.</span>
		)}
	      </div>
	    )}

    	    {buildStatus && (
	      <div className="build-status">
		<FontAwesomeIcon icon="spinner" spin size="xs" />
	        {buildStatus}
	      </div>
	    )}
	  </div>
	  <div className="right">
	  </div>
	</div>

        {projectBuilds.length > 0 && 
	  <div className="build-list">
	    <table>
	      <thead>
		<tr>
		  <th>Version</th>
		  <th>Size</th>
		  <th>Build Time</th>
		  <th className="shrink">Preview</th>
		  <th className="shrink">Actions</th>
		  {isProMember && 
		    <th className="shrink">Self Host</th>
		  }
		</tr>
	      </thead>
	      <tbody>
		{projectBuilds.map((build) => (
		  <BuildItem
		    key={build.buildIndex}
		    isRelease={project.release && project.release.buildId === build.buildId}
		    build={build}
		    openBuildPreview={openBuildPreview}
		    isProMember={isProMember}
		    handleDelete={handleDelete}
		    handleRelease={handleRelease}
		    handleDownload={handleDownload}
		  />
		))}
	      </tbody>
	    </table>
	  </div>
	}
      </div>
    </Modal>
  )
}

export default forwardRef(PublishModal);
