import React, {useCallback, useEffect, useMemo, useState} from 'react';
import CardSetupForm from '../common/CardSetupForm';
import { useToasts } from 'react-toast-notifications';
import {useUser} from '../../contexts/user-context';

const Payment = () => {
  const {me, loadMe} = useUser();
  const { addToast } = useToasts();
  const [cardUpdated, setCardUpdated] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const currentCard = useMemo(() => {
    return me && me.stripePaymentMethod && me.stripePaymentMethod.card;
  }, [me]);

  const onAddSuccess = useCallback((setupIntent) => {
    addToast('Setup Card succeeded', { appearance: 'success', autoDismiss: true });
    loadMe();
    setShowAdd(false);
    setCardUpdated(true);
  }, [setCardUpdated, addToast, loadMe, setShowAdd]);

  useEffect(() => {
    loadMe();
  }, [loadMe]);

  return (
    <div className="payment">
      {currentCard && (
	<div className="current-card">
	  <div className="title">Current Card</div>
	  <div>ending in {currentCard.last4}</div>
	  <div>expires in {currentCard.exp_month}/{currentCard.exp_year}</div>
	</div>
      )}

      {cardUpdated && (
	<div>
	  (Your card has been updated. It might take a while a refresh. Please check back in a minute)
	</div>
      )}

      {!cardUpdated && (
	<button className="button primary" onClick={() => setShowAdd(true)}>{currentCard? "Change Card": "Add Card"}</button>
      )}

      {showAdd && (
	<CardSetupForm onAddSuccess={onAddSuccess}/>
      )}
    </div>
  )
}

export default Payment;
