import React, {useMemo, forwardRef, useCallback, useState} from 'react';
import {useUser} from '../../contexts/user-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToasts } from 'react-toast-notifications';
import UploadForm from './UploadForm';
import Modal from '../common/Modal';
import EditableText from '../common/EditableText';
import {readableFileSize} from '../../libs/file';

const AssetItem = ({asset, handleItemClick, handleItemRemove, handleItemUpdate}) => {
  const clickHandler = useCallback((e) => {
    e.stopPropagation();
    handleItemClick(asset);
  }, [asset, handleItemClick]);

  const removeHandler = useCallback((e) => {
    e.stopPropagation();
    handleItemRemove(asset);
  }, [asset, handleItemRemove]);

  const confirmEditName = useCallback((value) => {
    handleItemUpdate(asset, value);
  }, [asset, handleItemUpdate]);

  return (
    <div className="item">
      <div className="control">
        <div className="left">
	  {readableFileSize(asset.size)}
	</div>
        <div className="right">
    	  {!asset.isDefault && 
	    <FontAwesomeIcon className="trash-icon clickable" icon='trash' onClick={removeHandler}/>
	  }
	</div>
      </div>
      {asset.thumbnail && 
	<img className="thumbnail" alt={asset.name} src={asset.thumbnail} onClick={clickHandler}/>
      }

      {asset.type === 'audio' && 
	<div className="thumbnail">
	  <FontAwesomeIcon icon='headphones' onClick={clickHandler}/>
	</div>
      }

      <div className="name">
	<EditableText originalValue={asset.name} onConfirm={confirmEditName}/>
      </div>
    </div>
  )
}

const AssetModal = (props, ref) => {
  const [searchText, setSearchText] = useState("");
  const [show, setShow] = useState(null);
  const [processingStatus, setProcessingStatus] = useState(null);
  const user = useUser();

  const {selectType, selectAssetHandler} = props;
  const {loadAssets, removeAsset, updateAsset} = user;
  const { addToast } = useToasts();

  const handleItemRemove = useCallback(async (asset) => {
    const {error} = await removeAsset({assetId: asset.id});
    if (error) {
      addToast(error, { appearance: 'error', autoDismiss: true });
    }
  }, [addToast, removeAsset]);

  const handleItemUpdate = useCallback(async (asset, name) => {
    await updateAsset({assetId: asset.id, name});
  }, [updateAsset]);

  const handleItemClick = useCallback(async (asset) => {
    selectAssetHandler(asset);
  }, [selectAssetHandler]);

  const onOpen = useCallback(() => {
    loadAssets();
    setShow(null);
    setSearchText("");
  }, [loadAssets, setShow, setSearchText]);

  const handleUploadOpen = useCallback(async () => {
    setShow('upload');
  }, [setShow]);
  const handleUploadSaved = useCallback(async () => {
    setShow(null);
  }, [setShow]);
  const handleCancel = useCallback(() => {
    setShow(null);
  }, [setShow]);

  const handleSearchTextChange = useCallback((e) => {
    setSearchText(e.target.value);
  }, [setSearchText]);

  const filteredAssets = useMemo(() => {
    if (!user.assets) return [];
    const filtered = user.assets.filter((a) => {
      if (searchText !== '') {
	if (!a.name.toLowerCase().includes(searchText.toLowerCase())) return false;
      }
      if (selectType === 'TARGET' && a.type !== 'image') return false;
      return true;
    });
    filtered.sort((a1, a2) => {
      if (a1.isDefault) return -1;
      if (a2.isDefault) return -1;
      return a1.name < a2.name? -1: 1;
    });
    return filtered;
  }, [searchText, user.assets, selectType]);

  return (
    <Modal {...props} ref={ref} className="asset-modal" onOpen={onOpen} header={"My Assets"} forbidClose={!!processingStatus}>
      {show === null && (
	<div>
	  <div className="toolbar">
	    <div className="left">
	      <div className="search-wrapper">
		<FontAwesomeIcon icon="search" size="xs" />
		<input type="text" name="search" placeholder="search..." value={searchText} onChange={handleSearchTextChange}/>
	      </div>
	    </div>
	    <div className="right">
	      <button className="button primary" onClick={handleUploadOpen}>
		<FontAwesomeIcon icon="plus" size="xs" />
		Upload
	      </button>
	    </div>
	  </div>

	  {user.assetsLoading && <FontAwesomeIcon icon="spinner" spin />}
	  <div className="assets-list">
	    {filteredAssets.map((asset) => (
	      <AssetItem key={asset.id} asset={asset} handleItemUpdate={handleItemUpdate} handleItemClick={handleItemClick} handleItemRemove={handleItemRemove}/>
	    ))}
	  </div>
	</div>
      )}

      {show === 'upload' && <UploadForm selectType={selectType} handleCancel={handleCancel} handleSaved={handleUploadSaved} setProcessingStatus={setProcessingStatus}/> }

      {processingStatus && (
	<div className="processing">
	  <div className="inner">
	    <FontAwesomeIcon icon="spinner" spin size="xs" />
	    {processingStatus}
	  </div>
	</div>
      )}
    </Modal>
  )
}

export default forwardRef(AssetModal);
