import React, {forwardRef, useState, useCallback} from 'react';
import Modal from '../common/Modal';
import TextInput from '../common/TextInput';
import SubmitButton from '../common/SubmitButton';

const DeleteProjectModal = (props, ref) => {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');

  const {handleDelete} = props;

  const onOpen = useCallback((params) => {
    setProject(params.project);
    setText('');
    setLoading(false);
  }, [setText, setProject, setLoading]);

  const submit = useCallback(async () => {
    setLoading(true);
    await handleDelete(project.id);
    setLoading(false);
  }, [project, handleDelete]);

  return (
    <Modal className="delete-project-modal small" {...props} ref={ref} header={"Delete Project"} onOpen={onOpen}>
      {project && (
	<div className="common-form">
	  <div className="field">
	    <span><strong>Permanently</strong> delete project <strong>{project.title}</strong>?</span>
	  </div>

	  <div className="field">
	    Type the project name "{project.title}" in the text box, and submit
	  </div>

	  <div className="field">
	    <TextInput type="text" placeholder={project.name} value={text} setValue={setText} icon="lock" />
	  </div>

	  <div className="field">
	    <SubmitButton className="button primary warning" onClick={submit} disabled={text !== project.title} text="Submit" loading={loading} />
	  </div>
	</div>
      )}
    </Modal>
  )
}

export default forwardRef(DeleteProjectModal)
