import React, {useCallback, useState, useEffect, forwardRef} from 'react';
import Modal from '../common/Modal';
import SubmitButton from '../common/SubmitButton';
import CardSetupForm from '../common/CardSetupForm';
import {useUser} from '../../contexts/user-context';
import {useAccount} from '../../contexts/account-context';
import {useProject} from '../../contexts/project-context';
import { useToasts } from 'react-toast-notifications';

const UpgradeModal = (props, ref) => {
  const {project, refetch} = useProject();
  const { addToast } = useToasts();
  const {me, loadMe} = useUser();
  const {createSubscription} = useAccount();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadMe();
  }, [loadMe]);

  const doCreateSubscription = useCallback(async () => {
    const subResult = await createSubscription(project.id);
    if (!subResult) {
      addToast('Failed to create subscription. Your card has not been charged.', { appearance: 'error', autoDismiss: true });
      return;
    } else {
      addToast('Upgraded successful', { appearance: 'success', autoDismiss: true });
    }
    refetch();
    ref.current.close();
  }, [addToast, refetch, createSubscription, ref, project]);

  const handleSubmitWithCard = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    await doCreateSubscription();
  }, [setLoading, doCreateSubscription]);

  const onOpen = useCallback(async () => {
    setLoading(false);
  }, [setLoading]);

  const onAddSuccess = useCallback(async () => {
    await doCreateSubscription();
  }, [doCreateSubscription]);

  return (
    <Modal {...props} ref={ref} className="upgrade-modal small" header={"Purchase Summary"} onOpen={onOpen} forbidClose={loading}>
      <div className="main">
    	<div className="plan">
    	  <div className="title">Simple and transparent. No hidden cost.</div>
    	  <div>Upgrade your project for <strong>USD 25 a month</strong> to get:</div>
    	  <ul>
    	    <li>permanent app URL of your choice (https://XXX.app.pictarize.com)</li>
	    <li>continuous updates (same app URL for different builds)</li>
    	    <li>usage statistics</li>
    	    <li>remove Pictarize advertising banner</li>
	  </ul>
	</div>

        {me && me.stripePaymentMethod && (
	  <div className="form">
	    <div className="current-card">
	     Pay with your current card 
	     (ending in {me.stripePaymentMethod.card.last4}, expires in {me.stripePaymentMethod.card.exp_month}/{me.stripePaymentMethod.card.exp_year})
	    </div>
	    <div>
	      <SubmitButton className="pay-button primary button ga-event" data-ga-event="upgrade_project" loading={loading} onClick={handleSubmitWithCard} text="Pay $25"/>
	    </div>
	  </div>
	)}

        {me && !me.stripePaymentMethod && (
	  <div className="form">
	    <div className="guide">Please input your card details</div>
	    <CardSetupForm onAddSuccess={onAddSuccess}/>
	  </div>
	)}

	<div className="footnote">
	  <p>By purchasing, you accept the <a href="https://pictarize.com/terms" target="_blank" rel="noreferrer">Terms of Use</a> and acknowledge reading the <a href="https://pictarize.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy.</a></p>
	  <p>You also agree to auto renewal of your monthly subscription for US$25.00, which can be disabled at any time through your account.</p>
	  <p>Your card details will be saved for future purchases and subscription renewals.</p>
	</div>
      </div>
    </Modal>
  )
}
export default forwardRef(UpgradeModal);
