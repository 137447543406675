import {utils, THREE, EditorControls} from 'pictarize-lib';
import React, {forwardRef, useImperativeHandle, useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ModelPreview = forwardRef(({obj}, ref) => {
  const containerRef = useRef(null);
  const rendererRef = useRef(null);

  useImperativeHandle(ref, () => ({
    exportImage: () => {
      return new Promise((resolve) => {
	const renderer = rendererRef.current;
	const newImage = new Image();
	newImage.addEventListener('load', () => {
	  resolve(newImage);
	});
	newImage.src = renderer.domElement.toDataURL("image/png");
      });
    },
  }), []);

  useEffect(() => {
    const container = containerRef.current;
    const scene = new THREE.Scene();

    const width = 500;
    const height = 500;

    const box = new THREE.Box3().setFromObject(obj);
    const maxSize = Math.max(box.max.x - box.min.x, box.max.y - box.min.y);
    const scale = 1000 / maxSize;
    obj.scale.set(scale, scale, scale);

    scene.add(obj);

    const color = 0xffffff;
    const intensity = 1;
    const light = new THREE.DirectionalLight( color, intensity );
    light.position.set( 2.5, 5, 3.8 );
    scene.add(light);

    const canvas = container.getElementsByTagName("canvas")[0];
    const renderer = new THREE.WebGLRenderer({canvas, antialias: true, preserveDrawingBuffer: true});
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.setClearColor( 0xebeced );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize(width, height, false);

    rendererRef.current = renderer;

    const camera = new THREE.PerspectiveCamera(50, 1, 1, 100000);
    camera.position.set(1000, 1000, 1000);

    camera.lookAt(new THREE.Vector3());

    camera.aspect = width / height;
    camera.updateProjectionMatrix();

    const editorControls = new EditorControls(camera, container);
    editorControls.addEventListener('change', () => {
      renderer.render(scene, camera);
    });

    renderer.render(scene, camera);
    setTimeout(() => {
      renderer.render(scene, camera); // some model failed to render in the first call. not sure why....
    }, 10);

    return () => {
      utils.disposeObject(obj);
      renderer.dispose();
    }
  }, [obj]);

  return (
    <div ref={containerRef} className="preview-model">
      <canvas/>
      <div className="guide">
	<FontAwesomeIcon className="icon" icon="mouse"/>
	<FontAwesomeIcon className="icon" icon="arrows-alt"/>
      </div>
    </div>
  )
});

export default ModelPreview;
