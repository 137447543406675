import React, {useState, useRef, useEffect} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useApolloClient, gql } from '@apollo/client';
import Loading from './components/loading/Loading';
import Login from './components/login/Login';
import Admin from './components/admin/Admin';
import Dashboard from './components/dashboard/Dashboard';
import Account from './components/account/Account';
import Project from './components/project/Project';
import {useAuth} from './contexts/auth-context';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const STRIPE_ACC = process.env.REACT_APP_STRIPE_ACC;
const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLIC;

const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($refreshToken: UUID!, $userId: String!) {
    refreshToken(refreshToken: $refreshToken, userId: $userId) {
      userId, 
      token,
      refreshToken
    }
  }
`;

const PrivateRoute = ({path, component}) => {
  const {authState} = useAuth();

  if (!authState.userId) {
    return <Redirect to={"/login"} />
  }
  return <Route exact path={path} component={component} />
}

const LoginRoute = ({path, component}) => {
  const {authState} = useAuth();
  if (authState.userId) {
    return <Redirect to={"/dashboard"} />
  }
  return <Route exact path={path} component={component} />
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_KEY, {
  stripeAccount: STRIPE_ACC
});

const App = () => {
  const [initialized, setInitialized] = useState(false);
  const auth = useAuth();
  const client = useApolloClient();

  const initRef = useRef({auth, client});

  // try to re-authenticate with the existing refreshToken
  // if successful, then go to dashboard,
  // else clear storage and go to login
  useEffect(() => {
    const init = async() => {
      const {auth, client} = initRef.current;
      const {userId, refreshToken} = await auth.initialize();
      //console.log("app initialize", userId, refreshToken);

      let refreshResult = null;
      if (userId && refreshToken) {
	try {
	  refreshResult = await client.mutate({
	    mutation: REFRESH_TOKEN_MUTATION,
	    variables: {userId, refreshToken},
	  });
	  //console.log("app init mutate result", refreshResult);
	} catch(e) {
	  console.log("app init mutate error", e);
	}
      }

      if (refreshResult) {
	const {userId, token, refreshToken} = refreshResult.data.refreshToken;
	auth.signIn({userId, token, refreshToken});
      } else {
	auth.signOut();
      }
      setInitialized(true);
    }
    init();
  }, []);

  if (!initialized) return <Loading/>;

  return (
    <div className="App">
      <Elements stripe={stripePromise}>
	<Switch>
	  <Redirect exact from ="/" to="/login" />
	  <LoginRoute exact path="/login" component={Login} />
	  <PrivateRoute exact path="/dashboard" component={Dashboard} />
	  <PrivateRoute exact path="/account" component={Account} />
	  <PrivateRoute exact path="/project/:projectId" component={Project} />
	  <PrivateRoute exact path="/adm135" component={Admin} />
	</Switch>
      </Elements>
    </div>
  )
}

export default App;
