import Signals from 'signals';
import {THREE} from 'pictarize-lib';
import Target from './Target';

class Editor {
  constructor() {
    this.signals = {
      targetsChanged: new Signals(),
      targetChanged: new Signals(),
      transformModeChanged: new Signals(),
      itemRemoved: new Signals(),
      selectedTargetChanged: new Signals(),
      selectedItemChanged: new Signals(),
      selectedItemPropertiesChanged: new Signals(),
      selectedItemViewportPropertiesChanged: new Signals(),
      updated: new Signals(),
    }
    this.targets = [];
    this.selectedTarget = null;
    this.selectedItem = null;
    this.transformMode = "translate";
    this.scene = new THREE.Scene();
  }

  dispose() {
    this.targets.forEach((target) => {
      target.dispose();
    });
  }

  selectTarget(target) {
    if (this.selectedTarget) {
      this.scene.remove(this.selectedTarget.subScene);
    }

    if (target) {
      this.scene.add(target.subScene);
      this.selectItem(target.imageTarget);
    }

    this.selectedTarget = target;
    this.signals.selectedTargetChanged.dispatch(target);
  }

  selectItem(item) {
    this.selectedItem = item;
    this.signals.selectedItemChanged.dispatch(item);
  }

  setTransformMode(mode) {
    this.transformMode = mode;
    this.signals.transformModeChanged.dispatch(mode);
  }

  removeTarget(target) {
    if (this.selectedTarget === target) {
      this.scene.remove(target.subScene);
      this.selectTarget(null);
      this.signals.selectedTargetChanged.dispatch(null);
      this.signals.selectedItemChanged.dispatch(null);
    }
    target.dispose();

    this.targets = this.targets.filter((t) => t !== target);
    this.signals.targetsChanged.dispatch();
    this.signals.updated.dispatch();
  }

  updateTargetName(target, name) {
    target.updateName(name);
    this.signals.targetChanged.dispatch(target);
    this.signals.updated.dispatch();
  }

  updateContentName(content, name) {
    this.selectedTarget.updateContentName(content, name);
    this.signals.targetChanged.dispatch(this.selectedTarget);
    this.signals.updated.dispatch();
  }

  updateContentScript(content, script) {
    content.script = script;
    this.signals.updated.dispatch();
  }
  
  updateTargetScript(target, script) {
    target.script = script;
    this.signals.updated.dispatch();
  }

  updateContentText(content, text) {
    this.selectedTarget.updateContentText(content, text);
    this.signals.selectedItemPropertiesChanged.dispatch();
    this.signals.updated.dispatch();
  }

  updateContentProperty(content, property, value) {
    this.selectedTarget.updateContentProperty(content, property, value);
    this.signals.selectedItemPropertiesChanged.dispatch();
    //this._syncMeshUpdateBacktoContent(this.selectedItem);
    this.signals.updated.dispatch();
  }

  updateContentPropertyDone() {
    this._updateContentPropertyDone();
    this.signals.selectedItemPropertiesChanged.dispatch();
  }

  onViewportUpdatedContentProperty(isDone) {
    this.signals.selectedItemPropertiesChanged.dispatch();
    this.signals.selectedItemViewportPropertiesChanged.dispatch();
    if (isDone) {
      this._updateContentPropertyDone();
    }
  }

  _updateContentPropertyDone() {
    const mesh = this.selectedItem.mesh;
    if (mesh.userData.aspect) {
      const largeScale = Math.max(mesh.scale.x, mesh.scale.y);
      const smallScale = Math.min(mesh.scale.x, mesh.scale.y);
      const newScale = largeScale > this.selectedItem.properties.scale[0]? largeScale: smallScale; 
      mesh.scale.x = newScale;
      mesh.scale.y = newScale;
    }
    this._syncMeshUpdateBacktoContent(this.selectedItem);
    this.signals.updated.dispatch();
  }

  async updateImageTarget(target, asset) {
    await target.setImageTarget(asset);
    this.signals.targetChanged.dispatch(target);
    this.selectItem(target.imageTarget);
    this.signals.updated.dispatch();
  }

  async addTarget(asset) {
    const target = new Target();
    this.targets.push(target);
    await target.setImageTarget(asset);
    this.signals.targetsChanged.dispatch();
    this.signals.updated.dispatch();
    return target;
  }

  addTextContent() {
    if (!this.selectedTarget) return null;
    const text = {
      alignment: 'left',
      color: '#5E8BFA',
      backgroundColor: 'rgba(0,0,0,1)',
      fontFamily: '"Courier New", monospace',
      fontSize: 32,
      fontStyle: 'normal',
      fontWeight: 'normal',
      text: [
	'Twinkle, twinkle, little star,',
	'How I wonder what you are!',
	'Up above the world so high,',
	'Like a diamond in the sky.',
      ].join('\n'),
    }
    const content = this.selectedTarget.addTextContent(text);
    this._contentAdded(content);
  }

  async addEmbedContent(embed) {
    if (!this.selectedTarget) return null;
    const content = await this.selectedTarget.addEmbedContent(embed);
    this._contentAdded(content);
  }

  async addAssetContent(asset) {
    if (!this.selectedTarget) return null;
    const content = await this.selectedTarget.addAssetContent(asset);
    this._contentAdded(content);
  }

  _syncMeshUpdateBacktoContent(content) {
    const {mesh} = content;
    content.properties.position = mesh.position.toArray(); 
    content.properties.rotation = mesh.rotation.toArray(); 
    content.properties.scale = mesh.scale.toArray(); 
  }

  _contentAdded(content) {
    this.signals.targetsChanged.dispatch();
    this.signals.targetChanged.dispatch(this.selectedTarget);
    this.signals.updated.dispatch();
    this.selectItem(content);
  }

  async copyContent(content) {
    if (!this.selectedTarget) return null;

    const newContent = await this.selectedTarget.copyContent(content);
    this.selectedItem = newContent;
    this.signals.targetChanged.dispatch(this.selectedTarget);
    this.signals.selectedItemChanged.dispatch(this.selectedItem);
    this.signals.updated.dispatch();
  }

  removeContent(content) {
    if (!this.selectedTarget) return null;
    if (this.selectedItem === content) {
      this.selectItem(null);
    }
    this.selectedTarget.removeContent(content);
    this.signals.targetChanged.dispatch(this.selectedTarget);
    this.signals.itemRemoved.dispatch(content);
    this.signals.updated.dispatch();
  }

  async importData(targetsData) {
    let missing = 0;
    for (let i = 0; i < targetsData.length; i++) {
      const target = new Target();
      missing += await target.importData(targetsData[i]);
      this.targets.push(target);
    }
    this.signals.targetsChanged.dispatch();
    return missing === 0;
  }

  exportData() {
    const targets = this.targets.map((target) => {
      return target.exportData();
    });
    return targets;
  }
}

export default Editor;
