import React, {useEffect, useRef, useMemo} from 'react';
import {ApolloProvider, from, ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import fetch from 'isomorphic-unfetch';
import jwt from 'jsonwebtoken';
import {useAuth} from './contexts/auth-context';

const API_URL = process.env.REACT_APP_API_URL + "/graphql";

const createClient = ({authRef}) => {
  let refreshingToken = false;

  // Log any GraphQL errors or network error that occurred
  // if UNAUTHENTICATED error happens
  //   this happens when 1) token is not valid anymore, and 2) refreshToken also fails
  //   e.g. refreshToken also expired
  // then auto sign out the user
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
	console.log(
	  `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
	)
      );

      for (let err of graphQLErrors) {
	switch (err.extensions.code) {
	  case 'UNAUTHENTICATED':
	    console.log("unauthenticated error. auto signout now.");
	    authRef.current.signOut();
	    break;
	  default: 
	    break;
	}
      }
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  // add jwt token in header
  // also check whether the jwt token has expired, if yes, refresh a new one and replace the token
  const newAuthLink = setContext(async (_, { headers }) => {
    for (let i = 0; i < 10; i++) { // wait refresh token for at most 10 seconds
      if (!refreshingToken) break;
      console.log("refreshing token. wait 1 second...");
      await new Promise(resolve => setTimeout(resolve, 1000));
    }

    let token = authRef.current.authState.token;
    if (!token) return {};

    // 10 seconds buffer
    const needRefresh = jwt.decode(token)?.exp * 1000 - 10000 < Date.now();
    if (needRefresh) {
      console.log("jwt expired. refresh token now...");
      refreshingToken = true;

      const {userId, refreshToken} = authRef.current.authState;

      // refreshToken mutation
      const response = await fetch(`${API_URL}/api/graphql`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          query: `mutation {
	    refreshToken(userId: "${userId}", refreshToken: "${refreshToken}") {
	      userId
	      token,
	      refreshToken
	    }
	  }`,
        }),
      });
      const responseJson = await response.json();
      console.log("refresh token response", responseJson);

      const refreshResult = responseJson.data?.refreshToken;
      console.log("new refresh token", refreshResult);

      if (refreshResult) {
	const {userId, token: newToken, refreshToken} = refreshResult;
	authRef.current.signIn({userId, token: newToken, refreshToken});
	token = newToken;
      }

      refreshingToken = false;
    }

    return {
      headers: {
	...headers,
	authorization: token ? `${token}` : "",
      }
    }
  });

  const httpLink = createHttpLink({uri: API_URL});

  const client = new ApolloClient({
    link: from([newAuthLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
    connectToDevTools: true
  });

  return client;
};

const ApolloWrapper = ({children}) => {
  const auth = useAuth();
  const authRef = useRef(null);

  useEffect(() => {
    authRef.current = auth;
  }, [auth]);

  const client = useMemo(() => {
    return createClient({authRef: authRef});
  }, []);

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
   )
}

export default ApolloWrapper;
