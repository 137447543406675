import React, {useRef, useCallback, useEffect, useMemo} from 'react';
import { useToasts } from 'react-toast-notifications';
import {useUser} from '../../contexts/user-context';
import {useAccount} from '../../contexts/account-context';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import ContinueSubscriptionModal from './ContinueSubscriptionModal';
import moment from 'moment';

const PaidProjects = () => {
  const cancelModalRef = useRef(null); 
  const continueModalRef = useRef(null); 
  const {updateSubscription} = useAccount();
  const {projects, loadProjects} = useUser();
  const { addToast } = useToasts();

  const paidProjects = useMemo(() => {
    if (!projects) return [];
    return projects.filter((p) => p.stripeSubscription);
  }, [projects]);

  const submitUpdate = useCallback(async ({projectId, updateType}) => {
    const result = await updateSubscription({projectId, updateType});
    if (!result) {
      addToast('Failed to update', { appearance: 'error', autoDismiss: true });
    } else {
      addToast('Update succeeded', { appearance: 'success', autoDismiss: true });
      loadProjects();
    }
    cancelModalRef.current.close();
    continueModalRef.current.close();
  }, [addToast, loadProjects, updateSubscription]);

  const openCancelSubscription = useCallback((project) => {
    cancelModalRef.current.open({project});
  }, []);

  const openContinueSubscription = useCallback((project) => {
    continueModalRef.current.open({project});
  }, []);

  useEffect(() => {
    loadProjects();
  }, [loadProjects]);

  return (
    <div className="paid-projects">
      <CancelSubscriptionModal ref={cancelModalRef} submitUpdate={submitUpdate}/>
      <ContinueSubscriptionModal ref={continueModalRef} submitUpdate={submitUpdate}/>

      <table>
	<thead>
	  <tr>
	    <th>Project Name</th>
	    <th>Status</th>
	    <th>Price</th>
	    <th>Current subscription period</th>
	    <th>Auto Renew</th>
    	    <th></th>
	  </tr>
	</thead>
	<tbody>
          {paidProjects.length === 0 && (
	    <tr>
	      <td colSpan={6}>You currently have no paid subsriptions.</td>
	    </tr>
	  )}
	  {paidProjects.map((project) => (
	    <tr key={project.id}>
	      <td>
	    	{project.title}
	      </td>
	      <td>
	  	{project.stripeSubscription.status}
	      </td>
	      <td>
	    	USD $25 per month
	      </td>
	      <td>
	    	{moment(project.stripeSubscription.current_period_start * 1000).format("YYYY-MM-DD")}
	        &nbsp;to&nbsp;
	    	{moment(project.stripeSubscription.current_period_end * 1000).format("YYYY-MM-DD")}
	      </td>
	      <td>
	        {project.stripeSubscription.cancel_at_period_end? "NO": "YES"}
	      </td>
	      <td>
	        {!project.stripeSubscription.cancel_at_period_end && (
		  <button className="warning button" onClick={() => {openCancelSubscription(project)}}>Cancel Subscription...</button>
		)}
	        {project.stripeSubscription.cancel_at_period_end && (
		  <button className="primary button" onClick={() => {openContinueSubscription(project)}}>Continue Subscription...</button>
		)}
	      </td>
	    </tr>
	  ))}
	</tbody>
      </table>
    </div>
  )
}

export default PaidProjects;
