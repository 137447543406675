import React, {useState, forwardRef, useImperativeHandle} from "react";

const Checkbox = ({ label, onChange }, ref) => {
  const [checked, setChecked] = useState(false);

  useImperativeHandle(ref, () => ({
    setChecked: (value) => {
      setChecked(value);
    },
  }), [setChecked]);

  return (
    <div className="common-checkbox">
      <label>
	<input
	  type="checkbox"
	  name={label}
	  checked={checked}
	  onChange={onChange}
	  className="form-check-input"
	/>
	{label}
      </label>
    </div>
  )
};

export default forwardRef(Checkbox);
