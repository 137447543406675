import React, {useMemo, useEffect, useState, useCallback} from 'react';
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useUser} from '../../contexts/user-context';
import { useHistory } from "react-router-dom";
import { useAuth } from '../../contexts/auth-context';
import { useApolloClient } from '@apollo/client';

const MenuDropdown = ({openRegister}) => {
  const client = useApolloClient();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const user = useUser();
  const auth = useAuth();
  const {me} = user;

  const toggleDropdown = useCallback((e) => {
    e.stopPropagation();
    setShow(!show);
  }, [show, setShow]);

  const signOut = useCallback(() => {
    client.cache.reset();
    auth.signOut();
  }, [client, auth]);

  const openAccount = useCallback(() => {
    history.push("/account");
  }, [history]);

  const isRegistered = useMemo(() => {
    return !!(me && me.email);
  }, [me]);

  useEffect(() => {
    const listener = () => {setShow(false)};
    document.body.addEventListener('click', listener);
    return () => {
      document.body.removeEventListener('click', listener);
    };
  }, [setShow]);

  return (
    <div className="dropdown">

      <div className="handle" onClick={toggleDropdown}>
        {me && isRegistered && me.email}
        {me && !isRegistered && "Trial Account"}
	<FontAwesomeIcon icon="caret-down"/>
      </div>
      <div className={classnames('content', {show: show})}>
        {!isRegistered && (
	  <div className="item" onClick={openRegister}>
	    <FontAwesomeIcon icon="user"/>
	    Sign Up
	  </div>
	)}

        {isRegistered && (
	  <div className="item" onClick={openAccount}>
	    <FontAwesomeIcon icon="user"/>
	    Account
	  </div>
	)}

	<div className="item" onClick={signOut}>
	  <FontAwesomeIcon icon="sign-out-alt"/>
	  Logout
	</div>
      </div>
    </div>
  )
}

export default MenuDropdown;
