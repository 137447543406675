import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SubmitButton = ({className, loading, onClick, disabled, text}) => {
  return (
    <button type="submit" className={className} onClick={onClick} disabled={loading || disabled}>
      {loading && <FontAwesomeIcon icon="spinner" spin />}
      {!loading && text}
    </button>
  )
}

export default SubmitButton;
