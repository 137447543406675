import React, {useEffect, useCallback, useMemo, useRef} from 'react';
import RegisterModal from './RegisterModal';
import UpdatePasswordModal from './UpdatePasswordModal';
import {useUser} from '../../contexts/user-context';
import MenuDropdown from './MenuDropdown';
import { useToasts } from 'react-toast-notifications';

const Menu = () => {
  const registerModalRef = useRef(null);
  const passwordUpdateModalRef = useRef(null);
  const { addToast } = useToasts();
  const user = useUser();
  const {me} = user;

  const openRegister = useCallback(() => {
    registerModalRef.current.open();
  }, []);

  const onRegisterDone = useCallback(() => {
    registerModalRef.current.close();
    passwordUpdateModalRef.current.open();
    user.loadMe();
  }, [user]);

  const onPasswordUpdateDone = useCallback(() => {
    passwordUpdateModalRef.current.close();
    user.loadMe();
    addToast('Registration Completed!', { appearance: 'success', autoDismiss: true });
  }, [user, addToast]);

  const forceVerifyEmail = useMemo(() => {
    return (me && me.verifyingEmail);
  }, [me]);

  useEffect(() => {
    if (forceVerifyEmail) {
      registerModalRef.current.open();
    }
  }, [forceVerifyEmail, me]);

  return (
    <div className="common-menu">
      <RegisterModal ref={registerModalRef} onRegisterDone={onRegisterDone} forbidClose={!!forceVerifyEmail}/>
      <UpdatePasswordModal ref={passwordUpdateModalRef} onPasswordUpdateDone={onPasswordUpdateDone} forbidClose={true} />

      <div className="left">
	Pictarize Studio
      </div>
      <div className="right">
	<MenuDropdown openRegister={openRegister}/>
      </div>
    </div>
  )
}

export default Menu;
