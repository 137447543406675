import React, {useCallback, useContext} from 'react';
import { useApolloClient, gql } from '@apollo/client';
import {callMutation} from '../libs/graphql';

const AccountContext = React.createContext();

const CREATE_SUBSCRIPTION_MUTATION = gql`
  mutation CreateSubscription($projectId: ID!) {
    createSubscription(projectId: $projectId)
  }
`;

const UPDATE_SUBSCRIPTION_MUTATION = gql`
  mutation UpdateSubscription($projectId: ID!, $updateType: String!) {
    updateSubscription(projectId: $projectId, updateType: $updateType)
  }
`;

const CREATE_SETUP_INTENT_MUTATION = gql`
  mutation CreateSetupIntent {
    createSetupIntent
  }
`;

const AccountProvider = ({children}) => {
  const client = useApolloClient();


  const createSetupIntent = useCallback(async() => {
    const result = await callMutation({
      client,
      mutation: CREATE_SETUP_INTENT_MUTATION,
    });
    if (result.error) {
      return null;
    }
    return result.data.createSetupIntent;
  }, [client]);

  const createSubscription = useCallback(async(projectId) => {
    const result = await callMutation({
      client,
      mutation: CREATE_SUBSCRIPTION_MUTATION,
      variables: {
	projectId
      }
    });
    if (result.error) {
      return null;
    }
    return result.data.createSubscription;
  }, [client]);

  const updateSubscription = useCallback(async({projectId, updateType}) => {
    const result = await callMutation({
      client,
      mutation: UPDATE_SUBSCRIPTION_MUTATION,
      variables: {
	projectId,
	updateType
      }
    });
    if (result.error) {
      return null;
    }
    return result.data.updateSubscription;
  }, [client]);

  const value = {
    createSetupIntent,
    createSubscription,
    updateSubscription,
  }

  return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
}

const useAccount = () => useContext(AccountContext);

export {
  AccountProvider,
  useAccount,
}
