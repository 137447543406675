import React, {useEffect, useState, useCallback, useContext, useMemo} from 'react';
import { useApolloClient, useLazyQuery, gql } from '@apollo/client';
import fetch from 'isomorphic-unfetch';
import {callMutation} from '../libs/graphql';

const UserContext = React.createContext();

const GET_ASSETS = gql`
  query GetAssets {
    getAssets {
      id,
      publicPath,
      name,
      type,
      thumbnail,
      size,
      createdAt,
      isDefault
    }
  }
`;

const GET_PROJECT_ANALYTICS = gql`
  query GetProjectAnalytics {
    getProjectAnalytics {
      id,
      views
    }
  }
`;

const GET_PROJECTS = gql`
  query GetProjects {
    getProjects {
      id,
      title,
      createdAt,
      release,
      modifiedAt,
      subscribed,
      stripeSubscription
    }
  }
`;

const GET_TEMPLATE_PROJECTS = gql`
  query GetTemplateProjects {
    getTemplateProjects {
      title,
      projectId,
      thumbnail,
      descriptions
    }
  }
`;

const ME_QUERY = gql`
  query Me {
    me {
      id,
      email,
      verifyingEmail,
      storageSize,
      storageLimit,
      stripePaymentMethod,
      proMemberExpiredAt
    }
  }
`

const GENERATE_ASSET_UPLOAD_MUTATION = gql`
  mutation GenerateAssetUploadURL {
    generateAssetUploadURL {
      assetId,
      url
    }
  }
`;

const CREATE_ASSET_MUTATION = gql`
  mutation CreateAsset($assetId: ID!, $name: String!, $type: String!, $thumbnail: String) {
    createAsset(assetId: $assetId, name: $name, type: $type, thumbnail: $thumbnail) {
      id,
      publicPath,
      name,
      type,
      thumbnail,
      size,
      createdAt
    }
  }
`;

const REMOVE_ASSET_MUTATION = gql`
  mutation RemoveAsset($assetId: ID!) {
    removeAsset(assetId: $assetId) 
  }
`;

const UPDATE_ASSET_MUTATION = gql`
  mutation UpdateAsset($assetId: ID!, $name: String!) {
    updateAsset(assetId: $assetId, name: $name) {
      id,
      publicPath,
      name,
      type,
      thumbnail,
      size,
      createdAt
    }
  }
`;

const CREATE_PROJECT_MUTATION = gql`
  mutation {
    createProject {
      id,
      title,
      createdAt,
    }
  }
`;

const CLONE_PROJECT_MUTATION = gql`
  mutation CloneProject($projectId: ID!) {
    cloneProject(projectId: $projectId) {
      id
    }
  }
`;

const DELETE_PROJECT_MUTATION = gql`
  mutation DeleteProject($id: ID!) {
    deleteProject(id: $id)
  }
`;

const REGISTER_EMAIL_MUTATION = gql`
  mutation RegisterEmail($email: EmailAddress!) {
    registerEmail(email: $email)
  }
`;

const VERIFY_EMAIL_MUTATION = gql`
  mutation VerifyEmail($code: String!) {
    verifyEmail(code: $code)
  }
`;

const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword($password: String) {
    updatePassword(password: $password)
  }
`;

const UserProvider = ({children}) => {
  const client = useApolloClient();
  const [me, setMe] = useState(null);
  const [assets, setAssets] = useState([]);

  const [loadAssets, {loading: assetsLoading, data: assetsData}] = useLazyQuery(GET_ASSETS, {
    fetchPolicy: 'cache-and-network'
  });
  const [loadProjects, {loading: projectsLoading, data: projectsData, refetch: refetchProjects}] = useLazyQuery(GET_PROJECTS, {
    fetchPolicy: 'cache-and-network'
  });
  const [loadTemplateProjects, {data: templateProjectsData}] = useLazyQuery(GET_TEMPLATE_PROJECTS, {
    fetchPolicy: 'cache-and-network'
  });
  const [loadProjectAnalytics, {data: projectAnalyticsData}] = useLazyQuery(GET_PROJECT_ANALYTICS, {
    fetchPolicy: 'cache-and-network'
  });
  const [loadMe, {loading: meLoading, data: meData}] = useLazyQuery(ME_QUERY, {
    fetchPolicy: 'network-only'
  });

  //const assets = assetsData && assetsData.getAssets;
  useEffect(() => {
    if (assetsData) {
      setAssets([...assetsData.getAssets]);
    }
  }, [assetsData]);

  const projects = useMemo(() => {
    return projectsData && projectsData.getProjects;
    //return projectsData && projectsData.getProjects.sort((p1, p2) => p1.modifiedAt < p2.modifiedAt? -1: 1);
  }, [projectsData]);

  const templateProjects = useMemo(() => {
    if (!templateProjectsData) return [];
    return templateProjectsData.getTemplateProjects;
  }, [templateProjectsData]);

  const projectAnalytics = useMemo(() => {
    return projectAnalyticsData && projectAnalyticsData.getProjectAnalytics;
  }, [projectAnalyticsData]);

  useEffect(() => {
    if (!meData) return;
    setMe(meData.me);
  }, [meData, setMe]);

  const registerEmail = useCallback(async ({email}) => {
    const result = await callMutation({
      client,
      mutation: REGISTER_EMAIL_MUTATION,
      variables: {email}
    });
    return result;
  }, [client]);

  const verifyEmail = useCallback(async ({code}) => {
    const result = await callMutation({
      client,
      mutation: VERIFY_EMAIL_MUTATION,
      variables: {code}
    });
    return result;
  }, [client]);

  const updatePassword = useCallback(async ({password}) => {
    const result = await callMutation({
      client,
      mutation: UPDATE_PASSWORD_MUTATION,
      variables: {password}
    });
    return result;
  }, [client]);

  const createProject = useCallback(async () => {
    try {
      const result = await client.mutate({
	mutation: CREATE_PROJECT_MUTATION,
      });
      const projectId = result.data.createProject.id;
      return projectId;
    } catch (e) {
      console.log('e', e);
    }
  }, [client]);

  const cloneProject = useCallback(async (projectId) => {
    try {
      const result = await client.mutate({
	mutation: CLONE_PROJECT_MUTATION,
	variables: {projectId}
      });
      const newProjectId = result.data.cloneProject.id;
      return newProjectId;
    } catch (e) {
      console.log('e', e);
    }
  }, [client]);

  const deleteProject = useCallback(async (projectId) => {
    const result = await callMutation({
      client,
      mutation: DELETE_PROJECT_MUTATION,
      variables: {id: projectId}
    });
    refetchProjects();
    return result;
  }, [client, refetchProjects]);

  const uploadAsset = useCallback(async ({name, type, file, thumbnailImage}) => {
    const genResult = await callMutation({
      client,
      mutation: GENERATE_ASSET_UPLOAD_MUTATION
    });
    if (genResult.error) {
      return null;
    }
    const {assetId, url} = genResult.data.generateAssetUploadURL;

    const response = await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
	'x-amz-acl': 'public-read',
      },
    });
    if (!response.ok) {
      return null;
    }
    const createResult = await callMutation({
      client,
      mutation: CREATE_ASSET_MUTATION,
      variables: {
	assetId,
	name,
	type,
	thumbnail: thumbnailImage && thumbnailImage.src,
      }
    });
    if (createResult.error) {
      return null;
    }
    const newAsset = createResult.data.createAsset;

    setAssets([...assets, newAsset]);
    return newAsset;
  }, [client, assets, setAssets]);

  const removeAsset = useCallback(async ({assetId}) => {
    const removeResult = await callMutation({
      client,
      mutation: REMOVE_ASSET_MUTATION,
      variables: {
	assetId
      }
    });
    if (!removeResult.error) {
      setAssets([...assets.filter((a) => a.id !== assetId)]);
    }
    return removeResult;
  }, [client, assets, setAssets]);

  const updateAsset = useCallback(async ({assetId, name}) => {
    const updateResult = await callMutation({
      client,
      mutation: UPDATE_ASSET_MUTATION,
      variables: {
	assetId,
	name
      }
    });
    if (updateResult.error) {
      return false;
    }

    const index = assets.findIndex((a) => a.id === assetId);
    const updatedAsset = updateResult.data.updateAsset;
    //assets.splice(index, 1, Object.assign({}, assets[index], {name}));
    assets.splice(index, 1, updatedAsset);
    setAssets([...assets]);
    return true;
  }, [client, assets, setAssets]);

  const value = {
    uploadAsset,
    removeAsset,
    updateAsset,
    assets,
    assetsLoading,
    loadAssets,
    projects,
    templateProjects,
    projectAnalytics,
    projectsLoading,
    loadProjects,
    loadProjectAnalytics,
    loadTemplateProjects,
    createProject,
    deleteProject,
    cloneProject,
    me,
    meLoading,
    loadMe,
    registerEmail,
    verifyEmail,
    updatePassword,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

const useUser = () => useContext(UserContext);

export {
  UserProvider,
  useUser,
}
