const API_URL = process.env.REACT_APP_API_URL;

const getThumbnailURL = (videoMeta) => {
  return new Promise((resolve, reject) => {
    if (videoMeta.service === 'youtube') {
      const url = API_URL + "/youtube-thumbnail?id=" + videoMeta.id;
      //const url = "https://img.youtube.com/vi/" + videoMeta.id + "/0.jpg";
      resolve(url);
    }
    if (videoMeta.service === 'vimeo') {
      const metaURL = "https://vimeo.com/api/v2/video/" + videoMeta.id + ".json";
      fetch(metaURL).then((response) => {
	return response.json();
      }).then((data) => {
	resolve(data[0]['thumbnail_medium']);
      }).catch((e) => {
	//resolve(API_URL + "/youtube-thumbnail?id=MISSING");
	resolve("/images/video-thumbnail-missing.png");
	//resolve("https://img.youtube.com/vi/MISSING/0.jpg"); // just use the youtube not found thumbnail...
      });
    }
  });
}

export {
  getThumbnailURL
}
