import React, {useRef, useState, useEffect, useCallback} from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useEditor} from '../../contexts/editor-context';
import AssetModal from '../assetModal/AssetModal';
import AddContentModal from './AddContentModal';
import AddEmbedModal from './AddEmbedModal';
import ReactTooltip from 'react-tooltip';
import EditableText from '../common/EditableText';

const Item = ({content, selected}) => {
  const {editor} = useEditor();

  const removeClick = useCallback((e) => {
    e.stopPropagation();
    editor.removeContent(content);
  }, [editor, content]);

  const copyClick = useCallback((e) => {
    e.stopPropagation();
    editor.copyContent(content);
  }, [editor, content]);

  const selectClick = useCallback((e) => {
    e.stopPropagation();
    editor.selectItem(content);
  }, [editor, content]);

  const confirmEditName = useCallback((newName) => {
    editor.updateContentName(content, newName);
  }, [editor, content]);

  return (
    <div className={classnames('item', {selected: selected})} onClick={selectClick}>
      <div className="name">
        <EditableText originalValue={content.name} onConfirm={confirmEditName}/>
      </div>
      <div className="right">
	<FontAwesomeIcon data-tip="copy" data-for="tip-editor-target-item" title="clone" className="copy-icon" icon='copy' onClick={copyClick}/>
	<FontAwesomeIcon data-tip="remove" data-for="tip-editor-target-item" className="trash-icon" icon='trash' onClick={removeClick}/>
      </div>
      <ReactTooltip id="tip-editor-target-item" getContent={(dataTip) => dataTip} />
    </div>
  )
}

const Target = ({target, selectedItem, expanded}) => {
  const [targetName, setTargetName] = useState('');
  const itemsRef = useRef(null);
  const [contents, setContents] = useState([]);
  const assetModalRef = useRef(null);
  const addContentModalRef = useRef(null);
  const addEmbedModalRef = useRef(null);
  const {editor} = useEditor();

  const selectAssetHandler = useCallback(async (asset) => {
    editor.addAssetContent(asset);
    assetModalRef.current.close();
  }, [editor]);

  const selectAddType = useCallback((type) => {
    addContentModalRef.current.close();
    if (type === 'asset') {
      assetModalRef.current.open();
    } else if (type === 'embed') {
      addEmbedModalRef.current.open();
    } else if (type === 'text') {
      editor.addTextContent();
    }
  }, [editor]);

  const addContentClick = useCallback((e) => {
    e.stopPropagation();
    addContentModalRef.current.open();
  }, []);

  const addEmbedHandler = useCallback((videoMeta) => {
    editor.addEmbedContent(videoMeta);
    addEmbedModalRef.current.close();
  }, [editor]);

  const targetClick = useCallback((e) => {
    e.stopPropagation();
    editor.selectTarget(target);
  }, [editor, target]);

  const removeClick = useCallback((e) => {
    e.stopPropagation();
    editor.removeTarget(target);
  }, [editor, target]);

  const confirmEditName = useCallback((value) => {
    editor.updateTargetName(target, value);
  }, [editor, target]);

  // target changed
  useEffect(() => {
    setContents([...target.contents]);
    setTargetName(target.name);
    const listener = (changedTarget) => {
      if (target !== changedTarget) return;
      setContents([...changedTarget.contents]);
      setTargetName(changedTarget.name);
    };
    editor.signals.targetChanged.add(listener);
    return () => {
      editor.signals.targetChanged.remove(listener);
    }
  }, [target, editor, setTargetName, setContents]);

  return (
    <div className="target">
      <AssetModal ref={assetModalRef} selectAssetHandler={selectAssetHandler}/>
      <AddContentModal ref={addContentModalRef} selectAddType={selectAddType}/>
      <AddEmbedModal ref={addEmbedModalRef} addEmbedHandler={addEmbedHandler}/>

      <div className={classnames("image-target", "item", {expanded})} onClick={targetClick}>
	<FontAwesomeIcon className="icon" icon={expanded? 'caret-down': 'caret-up'}/>
        <div className="name">
	  <EditableText originalValue={targetName} onConfirm={confirmEditName}/>
	</div>
	<div className="right">
	  <FontAwesomeIcon data-tip="remove" data-for="tip-editor-target" className="trash-icon" icon='trash' onClick={removeClick}/>
	</div>
      </div>

      <div ref={itemsRef} className={classnames("items", {show: expanded})}>
        {contents.map((content) => (
  	  <Item key={content.uuid} content={content} selected={content===selectedItem}/>
        ))}

    	<div className="item" onClick={addContentClick}>
	  <FontAwesomeIcon className="icon" icon="plus" />
	  <div className="name">
	    Add Content
	  </div>
	</div>
      </div>

      <ReactTooltip id="tip-editor-target" getContent={(dataTip) => dataTip} />
    </div>
  )
}

export default Target;
