import React, {useCallback} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TextInput = ({type, icon, placeholder, value, setValue, readOnly, disabled}) => {
  const onChange = useCallback((e) => {
    setValue(e.target.value);
  }, [setValue]);

  return (
    <div className="common-input">
      {icon && <FontAwesomeIcon icon={icon} />}
      <input type={type} disabled={disabled} placeholder={placeholder} readOnly={readOnly} value={value} onChange={onChange}/>
    </div>
  )
}

export default TextInput;
