import React, {useCallback, forwardRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from '../common/Modal';

const AddContentModal = (props, ref) => {
  const {selectAddType} = props;

  const selectAssets = useCallback(() => {
    selectAddType('asset');
  }, [selectAddType]);
  const selectEmbed = useCallback(() => {
    selectAddType('embed');
  }, [selectAddType]);
  const selectText = useCallback(() => {
    selectAddType('text');
  }, [selectAddType]);

  return (
    <Modal {...props} ref={ref} className="add-content-modal" header={"Add Content"}>
      <div>
    	<div className="type-list">
    	  <div className="type" onClick={selectAssets}>
	    <FontAwesomeIcon className="icon" icon="cube" />
    	    <div className="title">Upload Assets</div>
	    <div className="sub-title">3D Models, Images, etc.</div>
	  </div>
    	  <div className="type" onClick={selectEmbed}>
	    <FontAwesomeIcon className="icon" icon="video" />
    	    <div className="title">Embed Video</div>
	    <div className="sub-title">Youtube, Vimeo, etc.</div>
	  </div>
    	  <div className="type" onClick={selectText}>
	    <FontAwesomeIcon className="icon" icon="file-alt" />
    	    <div className="title">Text</div>
    	    <div className="sub-title"></div>
	  </div>
	</div>
      </div>
    </Modal>
  )
}

export default forwardRef(AddContentModal);
