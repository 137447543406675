const placeholderScript = `function onInit( {target, data} ) {
  // when scene is loaded (but target image is not yet detected)
  // you can do some prepatation work here
  // this event only fire ONCE
}

function onActivate( {target, data} ) {
  // when target image is being detected, and the effect is about to start
  // this event fires every time a target image is detected (e.g. after lost track)
}

function onDeactivate( {target, data} ) {
  // when target image is lost track, and the effect is about to end
}

function onUpdate( {target, data, time, deltaTime } ) {
  // this event is called on every frame
}

function onClick( {target, data, object, time} ) {
  // this event is called when any object from the scene is being clicked (i.e. tapped)
}`

export {
  placeholderScript,
}
