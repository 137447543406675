const callMutation = ({client, mutation, variables}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await client.mutate({mutation, variables});
      resolve({error: null, data: result.data});
      //onComplete(null, result.data);
    } catch (e) {
      const firstError = e.graphQLErrors && e.graphQLErrors[0];
      if (firstError && firstError.extensions && firstError.extensions.code === "DISPLAY") {
	//onComplete(firstError.message, null);
	resolve({error: firstError.message, data: null});
      } else {
	console.log("unknown error", e);
	//onComplete("Unknown Error", null);
	resolve({error: "unknown error", data: null});
      }
    }
  });
};

export {
  callMutation,
}
