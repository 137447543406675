import React, {useRef, useState, useCallback} from 'react';
import { useHistory } from "react-router-dom";
import { useApolloClient, gql } from '@apollo/client';
import { useAuth } from '../../contexts/auth-context';
import validator from 'validator';
import {callMutation} from '../../libs/graphql';
import SubmitButton from '../common/SubmitButton';
import TextInput from '../common/TextInput';
import ForgotPasswordModal from './ForgotPasswordModal';

const CREATE_USER_MUTATION = gql`
  mutation CreateUser($email: EmailAddress) {
    createUser(email: $email) {
      userId, 
      token,
      refreshToken,
    }
  }
`;

const LOGIN_MUTATION = gql`
  mutation Login($email: EmailAddress!, $password: String!) {
    login (email: $email, password: $password) {
      userId,
      token,
      refreshToken
    }
  }
`;

const Login = () => {
  const client = useApolloClient();
  const forgotPasswordModalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadingGuest, setLoadingGuest] = useState(false);
  const [formType, setFormType] = useState('signIn');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const auth = useAuth();
  const history = useHistory();

  const createGuest = useCallback(async (e) => {
    e.preventDefault();
    setLoadingGuest(true);
    const {data, error} = await callMutation({
      client,
      mutation: CREATE_USER_MUTATION,
    }); 
    setLoadingGuest(false);
    if (!error) {
      const {userId, refreshToken, token} = data.createUser;
      auth.signIn({userId, token, refreshToken});
      history.push("/");
    }
  }, [auth, client, history]);

  const register = useCallback(async (e) => {
    e.preventDefault();
    if (!validator.isEmail(email)) {
      setError("Invalid Email");
      return;
    }
    setError("");
    setLoading(true);
    const {data, error} = await callMutation({
      client,
      mutation: CREATE_USER_MUTATION,
      variables: {email: email.trim()},
    }); 
    setLoading(false);

    if (error) {
      setError(error);
    } else {
      const {userId, refreshToken, token} = data.createUser;
      auth.signIn({userId, token, refreshToken});
      history.push("/");
    }
  }, [email, auth, client, history]);

  const login = useCallback(async (e) => {
    e.preventDefault();
    if (!validator.isEmail(email)) {
      setError("Invalid Email");
      return;
    }
    setError("");

    setLoading(true);
    const {data, error} = await callMutation({
      client,
      mutation: LOGIN_MUTATION,
      variables: {email: email.trim(), password},
    }); 
    setLoading(false);
    if (error) {
      setError(error);
    } else {
      const {userId, refreshToken, token} = data.login;
      auth.signIn({userId, token, refreshToken});
      history.push("/");
    }
  }, [email, password, setLoading, auth, client, history]);

  const handleOpenForgotPassword = useCallback((e) => {
    e.preventDefault();
    forgotPasswordModalRef.current.open();
  }, []);

  return (
    <div className="login">
      <ForgotPasswordModal ref={forgotPasswordModalRef}/>

      <div className="introduction">
    	<div className="title">
	  Pictarize Studio
	</div>
      </div>

      <div className="main">
    	<form className="form common-form">
    	  <div className="title">
    	    {formType === 'signIn' && <span>Sign In</span>}
    	    {formType === 'signUp' && <span>Register</span>}
	  </div>

	  <div className="field">
	    <TextInput type="text" placeholder="Email" value={email} setValue={setEmail} icon="envelope" />
	  </div>

    	  {formType === 'signIn' && (
	    <div className="field">
	      <TextInput type="password" placeholder="Password" value={password} setValue={setPassword} icon="lock" />
	    </div>
	  )}

          <div className="field">
	    {formType === 'signIn' && (
	      <SubmitButton className="button primary ga-event" data-ga-event="login" onClick={login} text="Login" loading={loading} />
	    )}
	    {formType === 'signUp' && (
	      <SubmitButton className="button primary ga-event" data-ga-event="register" onClick={register} text="Register" loading={loading} />
	    )}
	  </div>
    
    	  {error &&
	    <div className="field error">
	      {error}
	    </div>
	  }

          <div className="switch-form">
    	    <div className="left">
	      {formType === 'signIn' && (
		<button className="link-button" onClick={() => {setFormType('signUp')}}>Register</button>
	      )}
	      {formType === 'signUp' && (
		<button className="link-button" onClick={() => {setFormType('signIn')}}>Sign In</button>
	      )}
	    </div>
    	    <div className="right">
    	      <button className="link-button" onClick={handleOpenForgotPassword}>Forgot password</button>
	    </div>
	  </div>

          <div className="field">
    	    <hr/>
	  </div>

          <div className="field">
    	      Don't want to register?
	  </div>
          <div className="field">
	    <SubmitButton className="button primary ga-event" data-ga-event="tryout" onClick={createGuest} text="Try out first" loading={loadingGuest} />

    	    <div className="footnote">
    	      Tryout project will vanish in 24 hours.
	    </div>
	  </div>
	</form>
      </div>
      <div className="terms">
	<a href="https://pictarize.com/terms" target="_blank" rel="noreferrer">Terms of Use</a> <a href="https://pictarize.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy.</a>
      </div>
    </div>
  )
}

export default Login;
