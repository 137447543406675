import React, {useState, useCallback} from 'react';
import {AdminProvider, useAdmin} from '../../contexts/admin-context';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';

const AdminInner = () => {
  const {projects, loadProjects, cloneProject, updateProMember} = useAdmin();
  const { addToast } = useToasts();
  const [updateUserId, setUpdateUserId] = useState("");
  const [proExpiredAt, setProExpiredAt] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useState('{}');

  const handleClone = useCallback(async (projectId) => {
    const result = await cloneProject(projectId);
    if (result) {
      addToast("clone succeeded", { appearance: 'success', autoDismiss: true });
    } else {
      addToast("clone failed", { appearance: 'error', autoDismiss: true });
    }
  }, [cloneProject, addToast]);

  const handleView = useCallback(async (projectId) => {
    window.open(window.location.protocol + "//" + window.location.host + "/project/" + projectId);
  }, []);

  const handleSearch = useCallback(() => {
    loadProjects({
      variables: {
	offset: parseInt(offset), 
	limit: parseInt(limit),
	query: JSON.parse(query),
      }
    });
  }, [loadProjects, limit, offset, query]);

  const handleUpdateProMember = useCallback(async () => {
    const result = await updateProMember(updateUserId, proExpiredAt);
    if (result) {
      addToast("update succeeded", { appearance: 'success', autoDismiss: true });
    } else {
      addToast("update failed", { appearance: 'error', autoDismiss: true });
    }
  }, [updateProMember, updateUserId, proExpiredAt]);

  console.log("projects", projects);

  return (
    <div className="admin">
      <div className="common-menu">
        <div className="left">A</div>
      </div>

      <div className="main">
        <div className="update-member">
    	  <div className="left">
    	    userId: <input size={40} value={updateUserId} onChange={(e) => {setUpdateUserId(e.target.value)}} />
    	    expired: <input size={20} value={proExpiredAt} onChange={(e) => {setProExpiredAt(e.target.value)}} />
    	    <span onClick={() => setProExpiredAt(moment().add(1, 'M').valueOf())}>1 month</span>
    	    <span onClick={() => setProExpiredAt(moment().add(1, 'Y').valueOf())}>1 year</span>
	    <button className="primary button" onClick={() => {handleUpdateProMember()}}>Update Pro Member</button>
	  </div>
	</div>
	<br/><br/>

        <div className="toolbar">
    	  <div className="left">
    	    query: <textarea value={query} onChange={(e) => {setQuery(e.target.value)}}></textarea>
    	    offset: <input type="number" value={offset} onChange={(e) => {setOffset(e.target.value)}} />
    	    limit: <input type="number" value={limit} onChange={(e) => {setLimit(e.target.value)}} />
    	    <button className="button primary" onClick={handleSearch}>Search</button>
	  </div>
    	  <div className="right">
	  </div>
	</div>

	<table>
	  <thead>
	    <tr>
	      <th>User ID</th>
	      <th>User</th>
	      <th>Title</th>
	      <th># release views</th>
	      <th># build (views)</th>
	      <th>Paid?</th>
	      <th>Modified</th>
    	      <th></th>
	    </tr>
	  </thead>
	  <tbody>
	    {projects.map(({user, project, projectAnalytic}) => {
	      const buildViews = project.builds.reduce((sum, build) => {
		return sum + (build.views? build.views: 0);
	      }, 0);

	      return (
		<tr key={project.id}>
		  <td>{user.id}</td>
		  <td>{user.email}</td>
		  <td>{project.title}</td>
		  <td>{projectAnalytic && projectAnalytic.views && projectAnalytic.views.count}</td>
		  <td>{project.builds.length} ({buildViews})</td>
		  <td>{project.subscribed? 'Yes': 'No'}</td>
		  <td>{moment(project.modifiedAt).format()}</td>
		  <td>
		    <button className="primary button" onClick={() => {handleView(project.id)}}>View</button>
		    <button className="primary button" onClick={() => {handleClone(project.id)}}>Clone to me</button>
		  </td>
		</tr>
	      )
	    })}
	  </tbody>
	</table>
      </div>
    </div>
  )
}

const Admin = () => {
  return (
    <AdminProvider>
      <AdminInner/>
    </AdminProvider>
  )
}

export default Admin;
