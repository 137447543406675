import React, {useState, useEffect, useRef, useCallback} from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useEditor} from '../../contexts/editor-context';

const Toolbar = () => {
  const containerRef = useRef(null);
  const {editor} = useEditor();
  const [show, setShow] = useState(false);
  const [transformMode, setTransformMode] = useState("translate");

  const translateClick = useCallback((e) => {
    e.stopPropagation();
    editor.setTransformMode('translate');
  }, [editor]);
  const rotateClick = useCallback((e) => {
    e.stopPropagation();
    editor.setTransformMode('rotate');
  }, [editor]);
  const scaleClick = useCallback((e) => {
    e.stopPropagation();
    editor.setTransformMode('scale');
  }, [editor]);

  useEffect(() => {
    const modeChangedListener = (mode) => {
      setTransformMode(mode);
    };
    editor.signals.transformModeChanged.add(modeChangedListener);

    const handler = (e) => {
      e.stopPropagation();
    };
    containerRef.current.addEventListener('mousedown', handler);
    containerRef.current.addEventListener('mouseup', handler);

    return () => {
      editor.signals.transformModeChanged.remove(modeChangedListener);
    }
  }, [editor, setTransformMode]);

  useEffect(() => {
    const listener = (selectedItem) => {
      setShow(!!selectedItem && selectedItem.isContent);
    };
    editor.signals.selectedItemChanged.add(listener);
    return () => {
      editor.signals.selectedItemChanged.remove(listener);
    }
  }, [editor, setShow]);

  return (
    <div ref={containerRef} className={classnames("transform-toolbar", {show: show})}>
      <div className="buttons">
	<button onClick={translateClick} className={classnames({selected: transformMode==='translate'})}>
	  <FontAwesomeIcon icon='arrows-alt' />
	</button>
	<button onClick={rotateClick} className={classnames({selected: transformMode==='rotate'})}>
	  <FontAwesomeIcon icon='sync-alt' />
	</button>
	<button onClick={scaleClick} className={classnames({selected: transformMode==='scale'})}>
	  <FontAwesomeIcon icon='expand-alt' />
	</button>
      </div>
    </div>
  )
}
export default Toolbar;
