import React, {useCallback, useState, forwardRef} from 'react';
import Modal from '../common/Modal';
import { useEditor} from '../../contexts/editor-context';
import {computeProjectSizes, readableFileSize} from '../../libs/file';

const ProjectSizeModal = (props, ref) => {
  const [assets, setAssets] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [healthStatus, setHealthStatus] = useState('');
  const {editor} = useEditor();

  const onOpen = useCallback((params) => {
    const targets = editor.targets;
    const {assets, healthStatus, totalSize} = computeProjectSizes(targets);
    setAssets(assets);
    setHealthStatus(healthStatus);
    setTotalSize(totalSize);
  }, [editor, setAssets, setTotalSize, setHealthStatus]);

  return (
    <Modal {...props} ref={ref} className="project-size-modal" header={"Project Size"} onOpen={onOpen}>
      <div>
    	{healthStatus === 'good' && (
	  <div className="status good">Your project size is {readableFileSize(totalSize)}, which is healthy!</div>
	)}
	{healthStatus === 'warning' && (
	  <div className="status warning">Your project size is {readableFileSize(totalSize)}, which is not good.</div>
	)}
	{healthStatus === 'bad' && (
	  <div className="status bad">Your project size is {readableFileSize(totalSize)}, which very bad! You should reduce the contents.</div>
	)}
	{healthStatus === 'critical' && (
	  <div className="status bad">Your project size is {readableFileSize(totalSize)}, which exceeded the 20Mb limit. You need to reduce contents.</div>
	)}

    	<div className="recommend">
          Remember, it's a browser environment, keeping project size low is critical to ensure fast loading time.<br/> 
    	  We recommend project size to be under 5Mb for good user experiences. It should never exceed 20Mb.
	</div>
    	<table className="common-table">
    	  <thead>
    	    <tr>
    	      <th>Asset</th>
    	      <th>Size</th>
	    </tr>
	  </thead>
    	  <tbody>
	    {assets.map((asset) => (
	      <tr key={asset.id}>
	      	<td>{asset.name}</td>
		<td>{readableFileSize(asset.size)}</td>
	      </tr>
	    ))}
    	    <tr>
    	      <td>
    		<strong>Total</strong>
	      </td>
    	      <td>
    		<strong>{readableFileSize(totalSize)}</strong>
	      </td>
	    </tr>
	  </tbody>
	</table>
      </div>
    </Modal>
  )
}

export default forwardRef(ProjectSizeModal);
