import React, {useCallback, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AssetModal from '../assetModal/AssetModal';
import {useEditor} from '../../contexts/editor-context';
import ReactTooltip from 'react-tooltip';

const AddTarget = () => {
  const assetModalRef = useRef(null);
  const tipRef = useRef(null);
  const {editor} = useEditor();

  const selectAssetHandler = useCallback(async (asset) => {
    const target = await editor.addTarget(asset);
    editor.selectTarget(target);
    assetModalRef.current.close();
  }, [editor]);

  const addClick = useCallback((e) => {
    e.stopPropagation();
    assetModalRef.current.open();
    tipRef.current.setAttribute("data-tip-disable", true);
    ReactTooltip.hide(tipRef.current);
  }, []);

  // targets changed listener
  useEffect(() => {
    const listener = () => {
      if (editor.targets.length === 0) {
	ReactTooltip.show(tipRef.current);
      } else {
	tipRef.current.setAttribute("data-tip-disable", true);
      }
    }
    editor.signals.targetsChanged.add(listener);
    return () => {
      editor.signals.targetsChanged.remove(listener);
    }
  }, [editor]);

  return (
    <div className="target">
      <AssetModal ref={assetModalRef} selectType="TARGET" selectAssetHandler={selectAssetHandler}/>

      <div ref={tipRef} className="image-target add-image-target item" onClick={addClick} data-tip="add"  data-for="tip-editor-addtarget">
	<div className="name">
	  <FontAwesomeIcon className="icon" icon="plus"/>
	  Add Target 
	</div>

	<ReactTooltip className='kickstart-tip' id="tip-editor-addtarget" place={'right'} border={true} type={'light'}>
    	  <span>Upload the first target image to kickstart!</span>
	</ReactTooltip>
      </div>
    </div>
  )
}

export default AddTarget;
