import React from 'react';
import { useParams } from 'react-router-dom';
import Menu from './Menu';
import Editor from '../editor/Editor';
import {ProjectProvider, useProject} from '../../contexts/project-context';
import {EditorProvider} from '../../contexts/editor-context';

const Project = () => {
  const {project} = useProject();
  if (!project) return null;

  return (
    <div className="editor">
      <Menu/>
      <Editor project={project} />
    </div>
  )
}

const ProjectWrapper = (props) => {
  const {projectId} = useParams();

  return (
    <ProjectProvider projectId={projectId}>
      <EditorProvider>
	<Project {...props} />
      </EditorProvider>
    </ProjectProvider>
  )
}

export default ProjectWrapper;
