import React, {forwardRef, useState, useCallback} from 'react';
import Modal from '../common/Modal';
import SubmitButton from '../common/SubmitButton';

const ContinueSubscriptionModal = (props, ref) => {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const {submitUpdate} = props;

  const onOpen = useCallback((params) => {
    setProject(params.project);
    setLoading(false);
  }, [setProject, setLoading]);

  const submit = useCallback(async () => {
    setLoading(true);
    submitUpdate({projectId: project.id, updateType: 'continue'});
    setLoading(false);
  }, [project, submitUpdate]);

  return (
    <Modal className="cancel-subscription-modal small" {...props} ref={ref} header={"Continue Subscription"} onOpen={onOpen}>
      {project && (
	<div className="common-form">
	  <div className="field">
	    <span>Continue paid subscription for the project <strong>{project.title}</strong></span>
	  </div>
	  <div className="field">
	    Your project release will stop working by the end of the current subscription period. If you wish to extend that, you need to re-enable Auto-renewal here.
	  </div>

	  <div className="field">
	    By clicking "Enable Auto-Renewal" button below, you understand that your card will be charged automatically at the end of subscription period.
	  </div>

	  <div className="field">
	    <SubmitButton className="button primary" onClick={submit} text="Enable Auto-Renewal" loading={loading} />
	  </div>
	</div>
      )}
    </Modal>
  )
}

export default forwardRef(ContinueSubscriptionModal)
