import React, {forwardRef, useState, useCallback} from 'react';
import Modal from '../common/Modal';
import TextInput from '../common/TextInput';
import SubmitButton from '../common/SubmitButton';
import {useUser} from '../../contexts/user-context';

const UpdatePasswordModal = (props, ref) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const user = useUser();

  const {onPasswordUpdateDone} = props;

  const onOpen = useCallback(() => {
    setLoading(false);
    setError('');
    setPassword('');
    setPassword2('');
  }, []);

  const submit = useCallback(async () => {
    if (password.length < 6) {
      setError("password too short");
      return;
    }
    if (password !== password2) {
      setError("Two password didn't match");
      return;
    }

    setError("");
    setLoading(true);
    const {error} = await user.updatePassword({password});
    setLoading(false);
    if (error) {
      setError(error);
    } else {
      onPasswordUpdateDone();
    }
  }, [onPasswordUpdateDone, user, password, password2]);

  return (
    <Modal className="setup-profile-modal small" {...props} ref={ref} header={"Update Password"} onOpen={onOpen}>
      <div className="common-form">
	<div className="field">
    	  <span>Please enter your password</span>
	</div>

	<div className="field">
	  <TextInput type="password" placeholder="Password (min 6 characters)" value={password} setValue={setPassword} icon="lock" />
	</div>

	<div className="field">
	  <TextInput type="password" placeholder="Enter Password Again" value={password2} setValue={setPassword2} icon="lock" />
	</div>

	<div className="field">
	  <SubmitButton className="button primary" onClick={submit} text="Submit" loading={loading} />
	</div>

        {error && (
	  <div className="field error">
	    {error}
	  </div>
	)}
      </div>
    </Modal>
  )
}

export default forwardRef(UpdatePasswordModal);
