import React, {useMemo, forwardRef, useState, useCallback} from 'react';
import Modal from '../common/Modal';
import TextInput from '../common/TextInput';
import SubmitButton from '../common/SubmitButton';
import {useUser} from '../../contexts/user-context';
import validator from 'validator';

const RegisterModal = (props, ref) => {
  const [changeEmail, setChangeEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const user = useUser();
  const {me} = user;

  const {onRegisterDone} = props;

  const onOpen = useCallback(() => {
    setLoading(false);
    setError('');
    setEmail('');
    setCode('');
  }, []);
  
  const submitEmail = useCallback(async () => {
    if (!validator.isEmail(email)) {
      setError("Invalid Email");
      return;
    }

    setError("");
    setLoading(true);
    const {error} = await user.registerEmail({email});
    setLoading(false);
    if (error) {
      setError(error);
    } else {
      setChangeEmail(false);
      user.loadMe();
    }
  }, [user, email]);

  const submitVerify = useCallback(async () => {
    setError("");
    setLoading(true);
    const {error} = await user.verifyEmail({code});
    setLoading(false);
    if (error) {
      setError(error);
    } else {
      onRegisterDone();
    }
  }, [onRegisterDone, user, code, setError]);

  const handleChangeEmail = useCallback(async() => {
    setChangeEmail(true);
  }, [setChangeEmail]);

  const isVerifyingEmail = useMemo(() => {
    return !changeEmail && me && me.verifyingEmail;
  }, [me, changeEmail]);

  return (
    <Modal className="register-modal small" {...props} ref={ref} header={"Sign Up"} onOpen={onOpen}>
      <div className="common-form">
	{!isVerifyingEmail && [
	  <div key={0} className="field">
	    <span>Please enter your email. Verification is required.</span>
	  </div>,

	  <div key={1} className="field">
	    <TextInput type="text" placeholder="Email" value={email} setValue={setEmail} icon="envelope" />
	  </div>,

	  <div key={2} className="field">
	    <SubmitButton className="button primary" onClick={submitEmail} text="Submit" loading={loading} />
	  </div>
	]}

	{isVerifyingEmail && [
	  <div key={0} className="field">
    	    <span>An email has sent to your inbox. Please check and find the verification code. Check spam box if not found.</span>
	  </div>,

	  <div key={1} className="field">
	    <TextInput type="text" placeholder="Email" value={me.verifyingEmail} readOnly icon="envelope" />
	  </div>,

	  <div key={2} className="field">
	    <TextInput type="text" placeholder="Verification Code" value={code} setValue={setCode} icon="lock" />
	  </div>,

	  <div key={3} className="field">
	    <SubmitButton className="button primary" onClick={submitVerify} text="Submit" loading={loading} />
	  </div>,

	  <div key={4} className="field">
	    <SubmitButton className="button negative" onClick={handleChangeEmail} text="Use Another Email"/>
	  </div>
	]}

        {error && (
	  <div className="field error">
	    {error}
	  </div>
	)}
      </div>
    </Modal>
  )
}

export default forwardRef(RegisterModal)
