import React, {forwardRef, useState, useCallback} from 'react';
import { useApolloClient, gql } from '@apollo/client';
import Modal from '../common/Modal';
import TextInput from '../common/TextInput';
import SubmitButton from '../common/SubmitButton';
import {callMutation} from '../../libs/graphql';
import validator from 'validator';

const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($email: EmailAddress!) {
    forgotPassword(email: $email) 
  }
`;
const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($email: EmailAddress!, $code: String!, $password: String!) {
    resetPassword(email: $email, code: $code, password: $password) 
  }
`;

const ForgotPasswordModal = (props, ref) => {
  const client = useApolloClient();

  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [isResetDone, setIsResetDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const onOpen = useCallback(() => {
    setLoading(false);
    setIsEmailSubmitted(false);
    setError('');
    setEmail('');
    setCode('');
    setPassword('');
    setPassword2('');
  }, []);

  const submitEmail = useCallback(async () => {
    if (!validator.isEmail(email)) {
      setError("Invalid Email");
      return;
    }

    setError('');
    setLoading(true);
    const {error} = await callMutation({
      client,
      mutation: FORGOT_PASSWORD_MUTATION,
      variables: {email}
    }); 
    setLoading(false);
    if (error) {
      setError(error);
    } else {
      setIsEmailSubmitted(true);
    }
  }, [client, setIsEmailSubmitted, email]);
  
  const submitReset = useCallback(async () => {
    if (code === '')  {
      setError("Please check email and enter the verification code");
      return;
    }
    if (password.length < 6)  {
      setError("Password too short");
      return;
    }
    if (password !== password2) {
      setError("Two passwords do not match");
      return;
    }
    setError("");
    setLoading(true);
    const {error} = await callMutation({
      client,
      mutation: RESET_PASSWORD_MUTATION,
      variables: {email, code, password}
    });
    setLoading(false);
    if (error) {
      setError(error);
    } else {
      setIsResetDone(true);
    }
  }, [client, email, code, password, password2, setError]);

  return (
    <Modal className="forgot-password-modal small" {...props} ref={ref} header={"Reset Password"} onOpen={onOpen}>
      <div className="common-form">
        {isResetDone && (
	  <div key={0} className="field">
	    <span>Your password is reset!</span>
	  </div>
	)}

	{!isResetDone && !isEmailSubmitted && [
	  <div key={0} className="field">
	    <span>Please enter your registration email.</span>
	  </div>,

	  <div key={1} className="field">
	    <TextInput type="text" placeholder="Email" value={email} setValue={setEmail} icon="envelope" />
	  </div>,

	  <div key={2} className="field">
	    <SubmitButton className="button primary" onClick={submitEmail} text="Submit" loading={loading} />
	  </div>
	]}

	{!isResetDone && isEmailSubmitted && [
	  <div key={0} className="field">
    	    <span>An email has sent to your inbox. Please check and find the verification code. Check spam box if not found.</span>
	  </div>,

	  <div key={1} className="field">
	    <TextInput type="text" placeholder="Email" value={email} readOnly icon="envelope" />
	  </div>,

	  <div key={2} className="field">
	    <TextInput type="text" placeholder="Verification Code from Email" value={code} setValue={setCode} icon="lock" />
	  </div>,

	  <div key={3} className="field">
	    <TextInput type="password" placeholder="New Password (at least 6 characters)" value={password} setValue={setPassword} icon="lock" />
	  </div>,

	  <div key={4} className="field">
	    <TextInput type="password" placeholder="Enter New Password Again" value={password2} setValue={setPassword2} icon="lock" />
	  </div>,

	  <div key={5} className="field">
	    <SubmitButton className="button primary" onClick={submitReset} text="Submit" loading={loading} />
	  </div>,
	]}

        {error && (
	  <div className="field error">
	    {error}
	  </div>
	)}
      </div>
    </Modal>
  )
}

export default forwardRef(ForgotPasswordModal)

