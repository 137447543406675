import React, {useCallback} from 'react';
import {useUser} from '../../contexts/user-context';
import { useHistory } from "react-router-dom";

const Menu = () => {
  const history = useHistory();
  const {me} = useUser();

  const backClick = useCallback(() => {
    history.goBack();
  }, [history]);

  if (!me) return null;

  return (
    <div className="common-menu">
      <div className="left">
        <div className="breadcrumb">
	  <div className="link" onClick={backClick}>
	    Back
	  </div>
	</div>
      </div>
      <div className="right">
        {me.email}
      </div>
    </div>
  )
}

export default Menu;
