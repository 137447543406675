import React, {useImperativeHandle, forwardRef, useRef, useCallback, useState} from 'react'
import { SketchPicker } from 'react-color'

const ColorPicker = forwardRef(({onPicked}, ref) => {
  const colorRef = useRef(null);
  const [show, setShow] = useState(false);
  const [color, setColor] = useState({r: '0', g: '0', b: '0', a: '1'});

  const handleClick = useCallback(() => {
    setShow(!show);
  }, [show, setShow]);

  const handleClose = useCallback(() => {
    setShow(false)
  }, [setShow]);

  const handleChange = useCallback((color) => {
    setColor(color.rgb);
    onPicked(color.rgb);
    colorRef.current.style.background = `rgba(${color.rgb.r}, ${color.rgb.g},${color.rgb.b}, ${color.rgb.a})`;
  }, [setColor, onPicked]);

  useImperativeHandle(ref, () => ({
    setColor: (color) => {
      colorRef.current.style.background = color;
    }
  }), []);

  return (
    <div className="common-colorpicker">
      <div className="swatch" onClick={handleClick}>
	<div className="color" ref={colorRef}/> 
      </div>

      {show && (
	<div className="popover">
	  <div className="cover" onClick={handleClose}/>
	  <SketchPicker color={color} onChange={handleChange} />
	</div>
      )}

    </div>
  )
});

export default ColorPicker; 
