const computeProjectSizes = (targets) => {
  const assetsMap = {};

  targets.forEach((target) => {
    const targetAsset = target.imageTarget && target.imageTarget.asset;

    if (targetAsset) {
      assetsMap[targetAsset.id] = {name: targetAsset.name, size: targetAsset.size};
    }

    target.contents.forEach((content) => {
      if (content.asset) {
	assetsMap[content.asset.id] = {name: content.asset.name, size: content.asset.size};
      }
    });
  });

  let assets = Object.values(assetsMap);
  assets = assets.filter((a) => !!a.size);
  assets = assets.sort((a1, a2) => {
    return a1.size > a2.size? -1: 1;
  });

  const totalSize = assets.reduce((acc, asset) => {
    return acc + asset.size;
  }, 0);

  let healthStatus = 'critical';
  if (totalSize < 5242880) { // 5Mb
    healthStatus = 'good';
  } else if (totalSize < 10485760) { // 10Mb
    healthStatus = 'warning';
  } else if (totalSize < 20971520) { // 20Mb
    healthStatus = 'bad';
  }
  //healthStatus = 'critical';

  return {
    assets,
    totalSize,
    healthStatus
  }
}

const readableFileSize = (fileSizeInBytes) => {
  let i = -1;
  let byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  } while (fileSizeInBytes > 1024);
  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

export {
  computeProjectSizes,
  readableFileSize
}
