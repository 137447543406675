import React, {useCallback, useState, useEffect, useRef, useMemo, forwardRef} from 'react';
import Modal from '../common/Modal';
import QRCode from '../common/QRCode';
import { useProject} from '../../contexts/project-context';
import { useEditor} from '../../contexts/editor-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SERVE_URL = process.env.REACT_APP_SERVE_URL;

const OpenAppModal = (props, ref) => {
  const [targetIndex, setTargetIndex] = useState(null);
  const [buildId, setBuildId] = useState(null);
  const targetRef = useRef(null);
  const {project} = useProject();
  const {editor} = useEditor();

  const releaseURL = useMemo(() => {
    if (!buildId) {
      const split = SERVE_URL.split("/");
      return split[0] + "//" + project.key + "." + split[2];
    }
    return SERVE_URL + "/p?projectId=" + project.id + "&buildId=" + buildId;
  }, [project, buildId]);

  const onOpen = useCallback((params) => {
    setTargetIndex(0);
    if (params && params.build) {
      setBuildId(params.build.buildId);
    } else {
      setBuildId(null);
    }
  }, [setTargetIndex, setBuildId]);

  const onClose = useCallback(() => {
    setTargetIndex(null);
  }, [setTargetIndex]);

  const next = useCallback(() => {
    setTargetIndex((targetIndex + 1) % editor.targets.length);
  }, [targetIndex, setTargetIndex, editor]);
  const prev = useCallback(() => {
    setTargetIndex((targetIndex - 1 + editor.targets.length) % editor.targets.length);
  }, [targetIndex, setTargetIndex, editor]);

  useEffect(() => {
    if (targetIndex === null || !editor.targets[targetIndex]) return;
    targetRef.current.src = editor.targets[targetIndex].imageTarget.image.src;
  }, [targetIndex, editor]);

  return (
    <Modal {...props} ref={ref} className="open-app-modal" header={"App"} onOpen={onOpen} onClose={onClose}>
      <div className="main">
	<div className="left">
	  <div className="title">
    	    {buildId && <span>Preview URL</span>}
    	    {!buildId && <span>Your app URL is</span>}
	  </div>
	  <div className="url">
	    {releaseURL} 
	  </div>
	  <div>
	    {releaseURL && <QRCode className="qrcode" url={releaseURL}/>}
	  </div>
	</div>
	<div className="right">
	  <div className="title">
            Target Images
	  </div>
	  <div className="images">
	    <FontAwesomeIcon icon="caret-left" size="3x" onClick={prev}/>
    	    <div className="target-image-wrapper">
	      <img alt="target" ref={targetRef} />
	    </div>
	    <FontAwesomeIcon icon="caret-right" size="3x" onClick={next}/>
	  </div>
	</div>
      </div>
      <div className="instruction">
    	<div className="title">
    	  Quick Testing Instructions
	</div>
    	Open the app page with your mobile device, and then scan the target images.
      </div>
    </Modal>
  )
}
export default forwardRef(OpenAppModal);
