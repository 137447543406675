import React, {useRef, useCallback, useState} from 'react';
import {useDropzone} from 'react-dropzone'
import {useUser} from '../../contexts/user-context';
import {checkIsGLTF, convertToGLB} from '../../libs/gltfConverter'; 
import {resizeImage, generateVideoPreview, generateModalPreview} from '../../libs/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModelPreview from './ModelPreview';
import {fileLoader} from 'pictarize-lib';

const {loadImage, loadFileSrc, loadGLBModel} = fileLoader;

const UploadForm = ({selectType, setProcessingStatus, handleCancel, handleSaved}) => {
  const modelViewerRef = useRef(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const user = useUser();

  const onDrop = useCallback(async (files) => {
    let file;

    // if gltf, then convert it to glb first
    const isGLTF = checkIsGLTF(files);
    if (isGLTF) {
      file = await convertToGLB(files);
    } else {
      file = files[0];
    }

    setProcessingStatus("uploading...");

    setError("");

    const extension = file.name.split( '.' ).pop().toLowerCase();
    const name = file.name.split(".")[0];

    //console.log("file type", file.type);

    if (file.type.match('video.*')) {
      const videoSrc = await loadFileSrc(file);
      setPreview({file, type: 'video', name, videoSrc});
    } else if (file.type.match('audio.*')) {
      const audioSrc = await loadFileSrc(file);
      setPreview({file, type: 'audio', name, audioSrc});
    } else if (file.type.match('image.*')) {
      const image = await loadImage(file);
      setPreview({file, type: 'image', name, image});
    } else if (extension === 'glb') {
      const model = await loadGLBModel(file, 1);
      const image = generateModalPreview(model);
      setPreview({file, type: 'glb', name, image, model});
    } else {
      setError("invalid file type");
    }
    setProcessingStatus(null);
  }, [setProcessingStatus]);

  const {getRootProps, getInputProps} = useDropzone({
    //multiple: false,
    multiple: true,
    onDrop
  });

  const handlePreviewNameChange = useCallback((e) => {
    setPreview({...preview, name: e.target.value});
  }, [preview, setPreview]);

  const handleSave = useCallback(async () => {
    let previewImage;
    let thumbnailImage;

    if (preview.type === 'image') {
      previewImage = preview.image;
    } else if (preview.type === 'glb') {
      previewImage = await modelViewerRef.current.exportImage();
    } else if (preview.type === 'video') {
      previewImage = await generateVideoPreview(preview.videoSrc);
      //document.body.appendChild(previewImage);
    }

    if (previewImage) {
      thumbnailImage = await resizeImage(previewImage, {targetSize: 100});
    }

    setLoading(true);
    const result = await user.uploadAsset({file: preview.file, type: preview.type, name: preview.name, thumbnailImage});
    setLoading(false);
    if (result) {
      handleSaved();
    } else {
      setError("failed to upload");
    }
  }, [preview, setLoading, handleSaved, user]);

  const handleRedo = useCallback(() => {
    setPreview(null);
  }, []);

  return (
    <div className="upload-form">
      <div className="top-bar">
	<button className="link-button" onClick={handleCancel}>Back</button>
      </div>

      <div className="main">
	{!preview && (
	  <div className="dropzone" {...getRootProps()}>
	    <input {...getInputProps()} />
	    <div className="title">Drop file here, or click to select</div>
	    <div className="format">
	      <div className="sub-title">Supported format</div>
	      <div>Image (.png, .jpg, .jpeg)</div>

	      {selectType !== 'TARGET' &&
		<div>Audio (.mp3)</div>
	      }

	      {selectType !== 'TARGET' &&
		<div>Video (.mp4)</div>
	      }

	      {selectType !== 'TARGET' &&
		<div>3D Model (.glb, .gltf - drop the whole folder)</div>
	      }
	    </div>
	  </div>
	)}

	{preview && (
	  <div className="preview">
	    <div className="field image-field">
	      {preview.type === 'video' && (
		<video controls>
		  <source src={preview.videoSrc} />
		</video>
	      )}
	      {preview.type === 'audio' && (
		<audio controls>
		  <source src={preview.audioSrc} />
		</audio>
	      )}
	      {preview.type === 'image' && (
		<img alt="preview" className="preview-image" src={preview.image && preview.image.src} />
	      )}
	      {preview.type === 'glb' && (
		<ModelPreview ref={modelViewerRef} obj={preview.model}/>
	      )}
	    </div>
	    <div className="field">
	      <input type="text" placeholder="name" value={preview.name} onChange={handlePreviewNameChange} />
	    </div>

	    {loading && <FontAwesomeIcon icon="spinner" spin />}

	    {!loading && (
	      <div className="field buttons-field">
		<button className="button negative" onClick={handleRedo}>Cancel</button>
		<button className="button primary" onClick={handleSave}>Save</button>
	      </div>
	    )}
	  </div>
	)}

    	{error && <div className="error">{error}</div>}
      </div>
    </div>
  )
};

export default UploadForm;
