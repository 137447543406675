import JSZip from 'jszip';
import {fileLoader} from 'pictarize-lib';
import {resizeImage} from './image';

const {loadBlobFromURL, loadImageFromURL} = fileLoader;

const TARGET_SIZE = 800;

const compileTargets = async (targets, progressCallback) => {
  const resizedImages = [];
  for (let t = 0; t < targets.length; t++) {
    const target = targets[t];
    const image = await loadImageFromURL(target.imageTarget.asset.publicPath);
    const resizedImage = await resizeImage(image, {targetSize: TARGET_SIZE});
    resizedImages.push(resizedImage);
  }
  const compiler = new window.MINDAR.Compiler();
  
  await compiler.compileImageTargets(resizedImages, (progress) => {
    progressCallback(progress);
  });

  const exportedBuffer = await compiler.exportData();
  const blob = new Blob([exportedBuffer]);
  return blob;
}

const generateBuildZip = async (projectSettings, targets, mindBlob) => {
  //console.log("build", targets, mindBlob);

  const zip = new JSZip();
  const assetsFolder = zip.folder("assets");
  const imagesFolder = zip.folder("images");

  const assets = {};
  const targetData = [];

  for (let t = 0; t < targets.length; t++) {
    const target = targets[t];
    targetData.push(target.exportData());

    for (const content of target.contents) {
      if (content.type === 'asset') {
	const {id, publicPath} = content.asset;
	if (!assets[id]) {
	  const blob = await loadBlobFromURL(publicPath);
	  assets[id] = blob;
	}
      }
    }
    const imageBlob = await loadBlobFromURL(target.imageTarget.asset.publicPath);
    imagesFolder.file('image'+t, imageBlob);
  }

  Object.keys(assets).forEach((id) => {
    assetsFolder.file(id, assets[id]);
  });

  const meta = {
    mindVersion: 2,
    projectSettings,
    createdAt: Date.now()
  }

  zip.file('targets.mind', mindBlob); 
  zip.file("targets.json", JSON.stringify(targetData)); 
  zip.file("meta.json", JSON.stringify(meta));

  const result = await zip.generateAsync({type: 'blob'});
  return result;
}

const downloadSelfHost = async(build) => {
  //const baseZip = "https://pictarize-storage.nyc3.digitaloceanspaces.com/selfhost/base.zip";
  //const baseZip = "https://mindar-studio.sfo3.digitaloceanspaces.com/selfhost/base.zip";
  const baseZip = process.env.REACT_APP_SELFHOST_BUILD;

  const response = await fetch(baseZip);
  const blob = await response.blob();

  const zip = new JSZip();
  await zip.loadAsync(blob);

  const responseBuild = await fetch(build.publicPath);
  const blobBuild = await responseBuild.blob();

  // removing all cloud stored path. maybe not necessary
  const buildZip = new JSZip();
  await buildZip.loadAsync(blobBuild);
  const targets = JSON.parse(await buildZip.file('targets.json').async('string'));
  for (let i = 0; i < targets.length; i++) {
    const target = targets[i];
    if (target.imageTarget && target.imageTarget.asset) {
      target.imageTarget.asset.publicPath = '';
    }
    if (target.imageTarget && target.imageTarget.asset && target.imageTarget.asset.extra && target.imageTarget.asset.extra.mindAsset) {
      target.imageTarget.asset.extra.mindAsset.publicPath = '';
    }
    for (const content of target.contents) {
      if (content.type === 'asset') {
	if (content.asset) {
	  content.asset.publicPath = '';
	}
      }
    }
  }
  buildZip.file("targets.json", JSON.stringify(targets)); 
  const updatedBlobBuild = await buildZip.generateAsync({type: 'blob'});

  //zip.file("build.zip", blobBuild);
  zip.file("build.zip", updatedBlobBuild);

  //console.log("final zip", zip);

  const result = await zip.generateAsync({type: 'blob'});

  const aLink = window.document.createElement('a');
  aLink.download = 'pictarize-build.zip';
  aLink.href = window.URL.createObjectURL(result);
  aLink.click();
  window.URL.revokeObjectURL(aLink.href);
}

export {
  generateBuildZip,
  compileTargets,
  downloadSelfHost
}
