import React, { useContext, useCallback, useState } from 'react';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    userId: undefined,
    refreshToken: undefined,
    token: undefined
  });

  const signIn = useCallback(({userId, refreshToken, token}) => {
    setAuthState({
      ...authState,
      userId,
      refreshToken,
      token,
    });
    localStorage.setItem('userId', userId);
    localStorage.setItem('refreshToken', refreshToken);
  }, [authState, setAuthState]);

  const setAuthToken = useCallback((token) => {
    setAuthState({
      ...authState,
      token,
    });
  }, [authState, setAuthState]);

  const signOut = useCallback(() => {
    setAuthState({
      userId: undefined,
      token: undefined,
      refreshToken: undefined
    });
    localStorage.removeItem('userId');
    localStorage.removeItem('refreshToken');
  }, [setAuthState]);

  const initialize = useCallback(() => {
    const userId = localStorage.getItem('userId');
    const refreshToken = localStorage.getItem('refreshToken');
    setAuthState({
      userId,
      refreshToken,
      token: undefined,
    });

    return {userId, refreshToken};
  }, [setAuthState]);

  return (
    <AuthContext.Provider value={{ authState, setAuthToken, signIn, signOut, initialize }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export {
  AuthProvider,
  useAuth
}
