import React, {useCallback, useMemo, useContext} from 'react';
import { useApolloClient, useQuery, gql } from '@apollo/client';
import {callMutation} from '../libs/graphql';

const ProjectContext = React.createContext();

const GET_PROJECT = gql`
  query GetProject($id: ID!) {
    project(id: $id) {
      id,
      title,
      builds,
      targets,
      release,
      key,
      settings,
      stripeSubscription,
      createdAt,
    }
  }
`;

const DELETE_PROJECT_BUILD_MUTATION = gql`
  mutation DeleteProjectBuild($id: ID!, $buildId: ID!) {
    deleteProjectBuild(id: $id, buildId: $buildId) 
  }
`;

const SAVE_PROJECT_MUTATION = gql`
  mutation SaveProject($id: ID!, $title: String, $targets: [JSON!], $settings: JSON) {
    saveProject(id: $id, title: $title, targets: $targets, settings: $settings) 
  }
`;

const RELEASE_PROJECT_MUTATION = gql`
  mutation ReleaseProject($id: ID!, $buildId: ID!) {
    releaseProject(id: $id, buildId: $buildId) 
  }
`;

const UNRELEASE_PROJECT_MUTATION = gql`
  mutation UnreleaseProject($id: ID!) {
    unreleaseProject(id: $id) 
  }
`;

const CREATE_BUILD_MUTATION = gql`
  mutation CreateProjectBuild($id: ID!, $buildId: ID!) {
    createProjectBuild(id: $id, buildId: $buildId) 
  }
`;

const SET_PROJECT_KEY_MUTATION = gql`
  mutation SetProjectKey($id: ID!, $key: String!) {
    setProjectKey(id: $id, key: $key) 
  }
`;

const GENERATE_BUILD_UPLOAD_MUTATION = gql`
  mutation GenerateProjectBuildUploadURL($id: ID!) {
    generateProjectBuildUploadURL(id: $id) {
      buildId,
      url
    }
  }
`;

const ProjectProvider = ({children, projectId}) => {
  const client = useApolloClient();
  const {data, refetch} = useQuery(GET_PROJECT, {
    variables: {
      id: projectId
    },
    fetchPolicy: 'network-only'
  });

  const saveProject = useCallback(async ({title, targets, settings}) => {
    const {error, data}  = await callMutation({
      client,
      mutation: SAVE_PROJECT_MUTATION,
      variables: {id: projectId, title, targets, settings}
    });
    if (!error) {
      refetch();
    }
    return {error, data}; 
  }, [client, refetch, projectId]);

  const setProjectKey  = useCallback(async ({key}) => {
    const {error, data}  = await callMutation({
      client,
      mutation: SET_PROJECT_KEY_MUTATION,
      variables: {id: projectId, key}
    });
    if (!error) {
      refetch();
    }
    return {error, data}; 
  }, [client, refetch, projectId]);

  const releaseProject = useCallback(async ({buildId}) => {
    const {error, data}  = await callMutation({
      client,
      mutation: RELEASE_PROJECT_MUTATION,
      variables: {id: projectId, buildId}
    });
    if (!error) {
      refetch();
    }
    return {error, data}; 
  }, [client, refetch, projectId]);

  const unreleaseProject = useCallback(async () => {
    const {error, data}  = await callMutation({
      client,
      mutation: UNRELEASE_PROJECT_MUTATION,
      variables: {id: projectId}
    });
    if (!error) {
      refetch();
    }
    return {error, data}; 
  }, [client, refetch, projectId]);

  const uploadBuild = useCallback(async ({zip}) => {
    const genResult = await callMutation({
      client,
      mutation: GENERATE_BUILD_UPLOAD_MUTATION,
      variables: {id: projectId}
    });
    if (genResult.error) {
      return null;
    }
    const {buildId, url} = genResult.data.generateProjectBuildUploadURL;

    const response = await fetch(url, {
      method: 'PUT',
      body: zip,
      headers: {
	'x-amz-acl': 'public-read',
      },
    });
    if (!response.ok) {
      return null;
    }
    const createResult = await callMutation({
      client,
      mutation: CREATE_BUILD_MUTATION,
      variables: {
	id: projectId,
	buildId,
      }
    });
    const {error} = createResult;
    if (!error) {
      refetch();
    }
    return createResult;
  }, [refetch, client, projectId]);

  const deleteProjectBuild = useCallback(async ({buildId}) => {
    const result = await callMutation({
      client,
      mutation: DELETE_PROJECT_BUILD_MUTATION,
      variables: {id: projectId, buildId}
    });
    refetch();
    return result;
  }, [refetch, client, projectId]);

  const project = useMemo(() => {
    if (!data) return null;
    return Object.assign({}, data.project);
  }, [data]);

  const value = {
    project,
    refetch,
    saveProject,
    deleteProjectBuild,
    setProjectKey,
    uploadBuild,
    releaseProject,
    unreleaseProject
  }

  return <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
}

const useProject = () => useContext(ProjectContext);

export {
  ProjectProvider,
  useProject,
}
