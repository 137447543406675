import React, {useState, useCallback} from 'react';
import SubmitButton from '../common/SubmitButton';
import TextInput from '../common/TextInput';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import {useAccount} from '../../contexts/account-context';
import { useToasts } from 'react-toast-notifications';
import stripeImg from '../../images/powered-by-stripe.png';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#272c33",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CardSetupForm = ({cancelAdd, onAddSuccess}) => {
  const stripe = useStripe();
  const elements = useElements();
  const {createSetupIntent} = useAccount();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [cardReady, setCardReady] = useState(false);

  const cardChanged = useCallback((e) => {
    setCardReady(e.complete);
  }, [setCardReady]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    const secret = await createSetupIntent();
    const payload = await stripe.confirmCardSetup(secret, {
      payment_method: {
        card: elements.getElement(CardElement),
	billing_details: {
	  name
	}
      }
    });

    if (payload.error) {
      addToast('Setup Card failed: ' + payload.error.message, { appearance: 'error', autoDismiss: true });
      setLoading(false);
    } else {
      if (onAddSuccess) {
	onAddSuccess();
      }
    }
  }, [onAddSuccess, elements, stripe, name, addToast, setLoading, createSetupIntent]);

  if (!stripe) return null;

  return (
    <form className="card-form common-card-form" onSubmit={handleSubmit}>
      <TextInput type="text" placeholder="Full Name" value={name} setValue={setName} icon="user" />
      <CardElement options={CARD_ELEMENT_OPTIONS} onChange={cardChanged}/>

      <div className="stripe-notes">
	<a href="https://stripe.com" target="_blank" rel="noreferrer"><img alt="stripe" src={stripeImg} /></a>
      </div>

      <div className="buttons">
        <SubmitButton className="primary button submit-button ga-event" data-ga-event="setup_card" disabled={!cardReady || !name} text="Save" loading={loading} onClick={handleSubmit}/>
      </div>
    </form>
  );
}

export default CardSetupForm;
