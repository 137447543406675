import React, {useState, useEffect} from 'react';
import {useEditor} from '../../contexts/editor-context';
import ImageTargetPropertiesPanel from './ImageTargetPropertiesPanel'; 
import ContentPropertiesPanel from './ContentPropertiesPanel'; 

const PropertiesPanel = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const {editor} = useEditor();

  useEffect(() => {
    const selectedItemChangdListener = (item) => {
      setSelectedItem(item);
    };
    editor.signals.selectedItemChanged.add(selectedItemChangdListener);
    return () => {
      editor.signals.selectedItemChanged.remove(selectedItemChangdListener);
    }
  }, [editor]);

  useEffect(() => {
    const listener = (target) => {
      setSelectedTarget(target);
    };
    editor.signals.selectedTargetChanged.add(listener);
    return () => {
      editor.signals.selectedTargetChanged.remove(listener);
    }
  }, [editor]);

  if (!selectedItem) return null;

  if (selectedItem.isTarget) {
    return <ImageTargetPropertiesPanel selectedTarget={selectedTarget} selectedItem={selectedItem}/>
  }
  if (selectedItem.isContent) {
    return <ContentPropertiesPanel selectedItem={selectedItem}/>
  }
  return null;
}

export default PropertiesPanel;
