import React, {useCallback, useEffect, useRef} from 'react';
import qrcode from 'qrcode';

const QRCode = ({className, url}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    qrcode.toCanvas(canvas, url, {width: 1000, light: '#0000'}, (error) => {
    });
  }, [url]);

  const downloadClick = useCallback(() => {
    const link = document.createElement('a');
    link.download = 'appcode.png';
    link.href = canvasRef.current.toDataURL('image/png');
    link.click();
  }, []);
  
  return (
    <div>
      <canvas className={className} ref={canvasRef} />
      <div>
	<button className="link-button" onClick={downloadClick}>Download QR Code</button>
      </div>
    </div>
  )
}

export default QRCode; 
