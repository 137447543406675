import {THREE} from 'pictarize-lib';
import React, {useRef, useEffect, useCallback} from 'react';
import {useEditor} from '../../contexts/editor-context';
import Checkbox from '../common/Checkbox';
import ColorPicker from '../common/ColorPicker';

const ContentPropertiesPanel = ({selectedItem}) => {
  const positionXRef = useRef(null);
  const positionYRef = useRef(null);
  const positionZRef = useRef(null);
  const rotationXRef = useRef(null);
  const rotationYRef = useRef(null);
  const rotationZRef = useRef(null);
  const scaleXRef = useRef(null);
  const scaleYRef = useRef(null);
  const scaleZRef = useRef(null);
  const userFacingRef = useRef(null);
  const videoClickToggleRef = useRef(null);
  const videoAutostartRef = useRef(null);
  const videoLoopRef = useRef(null);
  const videoMutedRef = useRef(null);
  const videoChromaRef = useRef(null);

  const animateAutostartRef = useRef(null);
  const animateLoopRef = useRef(null);

  const audioAutostartRef = useRef(null);
  const audioLoopRef = useRef(null);

  const textColorRef = useRef(null);
  const textBackgroundColorRef = useRef(null);
  const textFontStyleRef = useRef(null);
  const textAlignmentRef = useRef(null);
  const textFontSizeRef = useRef(null);
  const textFontFamilyRef = useRef(null);
  const textFontWeightRef = useRef(null);
  const textTextRef = useRef(null);

  const {editor} = useEditor();

  const assignValuesFromObject = useCallback((item) => {
    if (!item || !positionXRef.current) return;

    const object = item.mesh;
    positionXRef.current.value = object.position.x.toFixed(3);
    positionYRef.current.value = object.position.y.toFixed(3);
    positionZRef.current.value = object.position.z.toFixed(3);

    rotationXRef.current.value = (object.rotation.x * THREE.MathUtils.RAD2DEG).toFixed(3);
    rotationYRef.current.value = (object.rotation.y * THREE.MathUtils.RAD2DEG).toFixed(3);
    rotationZRef.current.value = (object.rotation.z * THREE.MathUtils.RAD2DEG).toFixed(3);

    scaleXRef.current.value = object.scale.x.toFixed(3);
    scaleYRef.current.value = object.scale.y.toFixed(3);
    scaleZRef.current.value = object.scale.z.toFixed(3);

    if (item.properties) {
      if (userFacingRef.current) {
	userFacingRef.current.setChecked(!!item.properties.userFacing);
      }
      if (animateLoopRef.current) {
	animateLoopRef.current.value = (item.properties.animateLoop);
      }
      if (animateAutostartRef.current) {
	animateAutostartRef.current.setChecked(!!item.properties.animateAutostart);
      }
      if (videoAutostartRef.current) {
	videoAutostartRef.current.setChecked(!!item.properties.videoAutostart);
      }
      if (videoLoopRef.current) {
	videoLoopRef.current.setChecked(!!item.properties.videoLoop);
      }
      if (videoMutedRef.current) {
	videoMutedRef.current.setChecked(!!item.properties.videoMuted);
      }
      if (videoClickToggleRef.current) {
	videoClickToggleRef.current.setChecked(!!item.properties.videoClickToggle);
      }
      if (videoChromaRef.current) {
	videoChromaRef.current.setChecked(!!item.properties.videoChroma);
      }

      if (audioAutostartRef.current) {
	audioAutostartRef.current.setChecked(!!item.properties.audioAutostart);
      }
      if (audioLoopRef.current) {
	audioLoopRef.current.setChecked(!!item.properties.audioLoop);
      }

      if (item.text) {
	if (textFontSizeRef.current) {
	  textFontSizeRef.current.value = item.text.fontSize;
	}
	if (textAlignmentRef.current) {
	  textAlignmentRef.current.value = item.text.alignment;
	}
	if (textFontFamilyRef.current) {
	  textFontFamilyRef.current.value = item.text.fontFamily;
	}
	if (textFontStyleRef.current) {
	  textFontStyleRef.current.value = item.text.fontStyle;
	}
	if (textFontWeightRef.current) {
	  textFontWeightRef.current.value = item.text.fontWeight;
	}
	if (textBackgroundColorRef.current) {
	  textBackgroundColorRef.current.setColor(item.text.backgroundColor);
	}
	if (textColorRef.current) {
	  textColorRef.current.setColor(item.text.color);
	}
	if (textTextRef.current) {
	  textTextRef.current.value = item.text.text;
	}
      }
    }
  }, []);

  useEffect(() => {
    assignValuesFromObject(editor.selectedItem);
    const listener = () => {
      if (editor.selectedItem) {
	setTimeout(() => {
	  assignValuesFromObject(editor.selectedItem);
	}, 1);
      }
    }
    editor.signals.selectedItemViewportPropertiesChanged.add(listener);
    return () => {
      editor.signals.selectedItemViewportPropertiesChanged.remove(listener);
    }
  }, [editor, assignValuesFromObject]);

  const changeDone = useCallback((e) => {
    editor.updateContentPropertyDone();
  }, [editor]);

  const changePositionX = useCallback((e) => {
    const object = selectedItem.mesh;
    const newPosition = new THREE.Vector3( parseFloat(e.target.value), object.position.y, object.position.z);
    editor.updateContentProperty(selectedItem, 'position', newPosition);
  }, [editor, selectedItem]);
  const changePositionY = useCallback((e) => {
    const object = selectedItem.mesh;
    const newPosition = new THREE.Vector3( object.position.x, parseFloat(e.target.value), object.position.z);
    editor.updateContentProperty(selectedItem, 'position', newPosition);
  }, [editor, selectedItem]);
  const changePositionZ = useCallback((e) => {
    const object = selectedItem.mesh;
    const newPosition = new THREE.Vector3( object.position.x, object.position.y, parseFloat(e.target.value));
    editor.updateContentProperty(selectedItem, 'position', newPosition);
  }, [editor, selectedItem]);

  const changeRotationX = useCallback((e) => {
    const object = selectedItem.mesh;
    const newRotation = new THREE.Euler(parseFloat(e.target.value) * THREE.MathUtils.DEG2RAD, object.rotation.y, object.rotation.z);
    editor.updateContentProperty(selectedItem, 'rotation', newRotation);
  }, [editor, selectedItem]);
  const changeRotationY = useCallback((e) => {
    const object = selectedItem.mesh;
    const newRotation = new THREE.Euler(object.rotation.x, parseFloat(e.target.value) * THREE.MathUtils.DEG2RAD, object.rotation.z);
    editor.updateContentProperty(selectedItem, 'rotation', newRotation);
  }, [editor, selectedItem]);
  const changeRotationZ = useCallback((e) => {
    const object = selectedItem.mesh;
    const newRotation = new THREE.Euler(object.rotation.x, object.rotation.y, parseFloat(e.target.value) * THREE.MathUtils.DEG2RAD);
    editor.updateContentProperty(selectedItem, 'rotation', newRotation);
  }, [editor, selectedItem]);

  const changeScaleX = useCallback((e) => {
    const object = selectedItem.mesh;
    const newScale = new THREE.Vector3(parseFloat(e.target.value), object.scale.y, object.scale.z);
    editor.updateContentProperty(selectedItem, 'scale', newScale);
  }, [editor, selectedItem]);
  const changeScaleY = useCallback((e) => {
    const object = selectedItem.mesh;
    const newScale = new THREE.Vector3(object.scale.x, parseFloat(e.target.value), object.scale.z);
    editor.updateContentProperty(selectedItem, 'scale', newScale);
  }, [editor, selectedItem]);
  const changeScaleZ = useCallback((e) => {
    const object = selectedItem.mesh;
    const newScale = new THREE.Vector3(object.scale.x, object.scale.y, parseFloat(e.target.value));
    editor.updateContentProperty(selectedItem, 'scale', newScale);
  }, [editor, selectedItem]);

  const changeUserFacing = useCallback((e) => {
    userFacingRef.current.setChecked(!!e.target.checked);
    editor.updateContentProperty(selectedItem, 'userFacing', !!e.target.checked);
  }, [editor, selectedItem]);

  const changeAnimateAutostart = useCallback((e) => {
    animateAutostartRef.current.setChecked(!!e.target.checked);
    editor.updateContentProperty(selectedItem, 'animateAutostart', !!e.target.checked);
  }, [editor, selectedItem]);

  const changeAnimateLoop = useCallback((e) => {
    editor.updateContentProperty(selectedItem, 'animateLoop', e.target.value);
  }, [editor, selectedItem]);

  const changeVideoAutostart = useCallback((e) => {
    videoAutostartRef.current.setChecked(!!e.target.checked);
    editor.updateContentProperty(selectedItem, 'videoAutostart', !!e.target.checked);
  }, [editor, selectedItem]);
  const changeVideoClickToggle = useCallback((e) => {
    videoClickToggleRef.current.setChecked(!!e.target.checked);
    editor.updateContentProperty(selectedItem, 'videoClickToggle', !!e.target.checked);
  }, [editor, selectedItem]);
  const changeVideoLoop = useCallback((e) => {
    videoLoopRef.current.setChecked(!!e.target.checked);
    editor.updateContentProperty(selectedItem, 'videoLoop', !!e.target.checked);
  }, [editor, selectedItem]);
  const changeVideoMuted = useCallback((e) => {
    videoMutedRef.current.setChecked(!!e.target.checked);
    editor.updateContentProperty(selectedItem, 'videoMuted', !!e.target.checked);
  }, [editor, selectedItem]);
  const changeVideoChroma = useCallback((e) => {
    videoChromaRef.current.setChecked(!!e.target.checked);
    editor.updateContentProperty(selectedItem, 'videoChroma', !!e.target.checked);
  }, [editor, selectedItem]);

  const changeAudioAutostart = useCallback((e) => {
    audioAutostartRef.current.setChecked(!!e.target.checked);
    editor.updateContentProperty(selectedItem, 'audioAutostart', !!e.target.checked);
  }, [editor, selectedItem]);
  const changeAudioLoop = useCallback((e) => {
    audioLoopRef.current.setChecked(!!e.target.checked);
    editor.updateContentProperty(selectedItem, 'audioLoop', !!e.target.checked);
  }, [editor, selectedItem]);

  const changeText = useCallback((property, value) => {
    const newText = Object.assign({}, editor.selectedItem.text, {
      [property]: value
    });
    editor.updateContentText(editor.selectedItem, newText);
  }, [editor]);

  if (!selectedItem) return null;

  return (
    <div>
      <div className="title">{selectedItem.name}</div>

      <div className="section">
	<div className="sub-title">Position</div>
	<div className="row">
	  <input ref={positionXRef} className="col" type="number" onChange={changePositionX} onBlur={changeDone} step="1" />
	  <input ref={positionYRef} className="col" type="number" onChange={changePositionY} onBlur={changeDone} step="1" />
	  <input ref={positionZRef} className="col" type="number" onChange={changePositionZ} onBlur={changeDone} step="1" />
	</div>

	<div className="sub-title">Rotation</div>
	<div className="row">
	  <input ref={rotationXRef} className="col" type="number" onChange={changeRotationX} onBlur={changeDone} />
	  <input ref={rotationYRef} className="col" type="number" onChange={changeRotationY} onBlur={changeDone} />
	  <input ref={rotationZRef} className="col" type="number" onChange={changeRotationZ} onBlur={changeDone} />
	</div>

	<div className="sub-title">Scale</div>
	<div className="row">
	  <input ref={scaleXRef} className="col" type="number" onChange={changeScaleX} onBlur={changeDone} step="1" />
	  <input ref={scaleYRef} className="col" type="number" onChange={changeScaleY} onBlur={changeDone} step="1" />
	  <input ref={scaleZRef} className="col" type="number" onChange={changeScaleZ} onBlur={changeDone} step="1" />
	</div>
      </div>

      {(selectedItem.type === 'text' || selectedItem.type === 'embed' || (selectedItem.type === 'asset' && selectedItem.asset.type === 'image')) && (
	<div className="section">
	  <div className="sub-title">Always facing user?</div>
	  <div className="row">
	    <Checkbox ref={userFacingRef} label="enabled" onChange={changeUserFacing}/>
	  </div>
	</div>
      )}

      {(selectedItem.type === 'asset' && selectedItem.asset.type === 'audio') && (
	<div className="section">
	  <div className="row">
	    <Checkbox ref={audioAutostartRef} label="auto play" onChange={changeAudioAutostart}/>
	  </div>
	  <div className="row">
	    <Checkbox ref={audioLoopRef} label="loop" onChange={changeAudioLoop}/>
	  </div>
	</div>
      )}
    
      {selectedItem.mesh.animations.length > 0 &&
	<div className="section">
	  <div className="sub-title">Animation</div>

	  <div className="row">
	    <Checkbox ref={animateAutostartRef} label="auto play" onChange={changeAnimateAutostart}/>
	  </div>
	  <div className="row">
	    <div className="label-col">
	      Loop
	    </div>
	    <div className="value-col">
	      <select ref={animateLoopRef} onChange={changeAnimateLoop}>
		<option value='once'>Once</option>
		<option value='repeat'>Repeat</option>
		<option value='pingpong'>PingPong</option>
	      </select>
	    </div>
	  </div>
	</div>
      }

      {(selectedItem.type === 'embed' || (selectedItem.type === 'asset' && selectedItem.asset.type === 'video')) && (
	<div className="section">
	  <div className="sub-title">Video Settings</div>
	  <div className="row">
	    <Checkbox ref={videoAutostartRef} label="auto play" onChange={changeVideoAutostart}/>
	  </div>
	  <div className="row">
	    <Checkbox ref={videoLoopRef} label="loop" onChange={changeVideoLoop}/>
	  </div>
	  <div className="row">
	    <Checkbox ref={videoMutedRef} label="muted" onChange={changeVideoMuted}/>
	  </div>
	  <div className="row">
	    <Checkbox ref={videoClickToggleRef} label="click to play/pause" onChange={changeVideoClickToggle}/>
	  </div>

	  {selectedItem.type === 'asset' && 
	    <div className="row">
	      <Checkbox ref={videoChromaRef} label="green screen removal" onChange={changeVideoChroma}/>
	    </div>
	  }

	  {selectedItem.type === 'embed' && 
	    <div className="row">
	      {selectedItem.embed.videoMeta.service === 'youtube' && (
		<a href={"https://youtube.com/watch?v="+selectedItem.embed.videoMeta.id} target="_blank" rel="noreferrer">Youtube Video</a>
	      )}
	      {selectedItem.embed.videoMeta.service === 'vimeo' && (
		<a href={"https://vimeo.com/"+selectedItem.embed.videoMeta.id} target="_blank" rel="noreferrer">Vimeo Video</a>
	      )}
	    </div>
	  }
	</div>
      )}

      {selectedItem.type === 'text' && (
	<div className="section">
	  <div className="row">
	    <div className="label-col">
	      Color
	    </div>
	    <div className="value-col">
	      <ColorPicker ref={textColorRef} onPicked={(v) => changeText('color', `rgba(${v.r},${v.g},${v.b},${v.a})`)}/>
	    </div>
	  </div>
	  <div className="row">
	    <div className="label-col">
	      Background
	    </div>
	    <div className="value-col">
	      <ColorPicker ref={textBackgroundColorRef} onPicked={(v) => changeText('backgroundColor', `rgba(${v.r},${v.g},${v.b},${v.a})`)}/>
	    </div>
	  </div>

	  <div className="row">
	    <div className="label-col">
	      Font
	    </div>
	    <div className="value-col">
	      <select ref={textFontFamilyRef} onChange={(e) => changeText('fontFamily', e.target.value)}>
		<option value='Arial, sans-serif'>Arial</option>
		<option value='Verdana, sans-serif'>Verdana</option>
		<option value='Helvetica, sans-serif'>Helvetica</option>
		<option value='Tahoma, sans-serif'>Tahoma</option>
		<option value='"Trebuchet", sans-serif'>Trebuchet</option>
		<option value='Times New Roman, serif'>Times New Roman</option>
		<option value='Georgia, serif'>Georgia</option>
		<option value='Garamond, serif'>Garamond</option>
		<option value='"Courier New", monospace'>Courier New</option>
		<option value='"Brush Script MT", cursive'>Brush Script MT</option>
	      </select>
	    </div>
	  </div>
	  <div className="row">
	    <div className="label-col">
	      Style
	    </div>
	    <div className="value-col">
	      <select ref={textFontStyleRef} onChange={(e) => changeText('fontStyle', e.target.value)}>
		<option value='normal'>normal</option>
		<option value='italic'>italic</option>
	      </select>
	    </div>
	  </div>
	  <div className="row">
	    <div className="label-col">
	      Weight
	    </div>
	    <div className="value-col">
	      <select ref={textFontWeightRef} onChange={(e) => changeText('fontWeight', e.target.value)}>
		<option value='normal'>normal</option>
		<option value='bold'>bold</option>
	      </select>
	    </div>
	  </div>
	  <div className="row">
	    <div className="label-col">
	      Size
	    </div>
	    <div className="value-col">
	      <input ref={textFontSizeRef} type="number" onChange={(e) => changeText('fontSize', parseInt(e.target.value))} step="1" />
	    </div>
	  </div>
	  <div className="row">
	    <div className="label-col">
	      Text
	    </div>
	    <div className="value-col">
	      <textarea ref={textTextRef} onBlur={(e) => changeText('text', e.target.value)}></textarea>
	    </div>
	  </div>
	  <div className="row">
	    <div className="label-col">
	      Align
	    </div>
	    <div className="value-col">
	      <select ref={textAlignmentRef} onChange={(e) => changeText('alignment', e.target.value)}>
		<option value="left">Left</option>
		<option value="center">Center</option>
		<option value="right">Right</option>
	      </select>
	    </div>
	  </div>
	</div>
      )}
    </div>
  )
};

export default ContentPropertiesPanel;
