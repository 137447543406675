import {readableFileSize} from '../../libs/file';
import {useUser} from '../../contexts/user-context';

const Storage = () => {
  const {me} = useUser();

  if (!me) return null;

  return (
    <div className="storage">
      <p>Current usage: <strong>{readableFileSize(me.storageSize)}</strong> / {readableFileSize(me.storageLimit)}</p>
      <p>It mainly comes from <strong>Project Builds</strong> and <strong>Uploaded Assets</strong>. If you have build projects for many times, consider removing the old ones. Also, occasionally clean up unncessary uploaded assets.</p>

      <p>This is a soft limit to avoid abuse. If you do need more storage space, please email hello@pictarize.com</p>
    </div>
  )
}

export default Storage;
