import React, {useState, useEffect} from 'react';
import {useEditor} from '../../contexts/editor-context';
import AddTarget from './AddTarget';
import Target from './Target';
import ProjectSize from './ProjectSize';

const ScenePanel = () => {
  const [targets, setTargets] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const {editor} = useEditor();

  // item changed listener
  useEffect(() => {
    const selectedItemChangdListener = (item) => {
      setSelectedItem(item);
    };
    editor.signals.selectedItemChanged.add(selectedItemChangdListener);
    return () => {
      editor.signals.selectedItemChanged.remove(selectedItemChangdListener);
    }
  }, [editor]);

  // targets changed listener
  useEffect(() => {
    const listener = () => {
      setTargets(editor.targets);
    }
    editor.signals.targetsChanged.add(listener);
    return () => {
      editor.signals.targetsChanged.remove(listener);
    }
  }, [editor]);

  // selected target changed listener
  useEffect(() => {
    const listener = () => {
      setSelectedTarget(editor.selectedTarget);
    }
    editor.signals.selectedTargetChanged.add(listener);
    return () => {
      editor.signals.selectedTargetChanged.remove(listener);
    }
  }, [editor]);

  return (
    <div className="scene-panel">
      {targets && targets.map((target, targetIndex) => (
	<Target key={target.uuid} target={target} expanded={selectedTarget===target} selectedItem={selectedItem}/>
      ))}


      <AddTarget/>

      <ProjectSize targets={targets} />
    </div>
  )
};

export default ScenePanel;
