import React, {useState, useEffect, useRef, useCallback} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ScenePanel from './ScenePanel';
import PropertiesPanel from './PropertiesPanel';
import TransformToolbar from './TransformToolbar';
import {useEditor} from '../../contexts/editor-context';
import { useToasts } from 'react-toast-notifications';
import PlayerModal from './PlayerModal';
import ScriptModal from './ScriptModal';

const Editor = ({project}) => {
  const playerModalRef = useRef(null);
  const scriptModalRef = useRef(null);
  const viewportContainerRef = useRef(null);
  const {editor, viewport} = useEditor();
  const [initStatus, setInitStatus] = useState("");
  const { addToast } = useToasts();

  useEffect(() => {
    if (initStatus !== '') return;
    const init = async() => {
      const success = await editor.importData(project.targets);
      if (editor.targets.length > 0) {
	editor.selectTarget(editor.targets[0]);
      }
      viewport.setup({editor, container: viewportContainerRef.current});

      if (!success) {
	addToast('There are some missing asset', { appearance: 'error', autoDismiss: true });
      }

      setInitStatus('initialized');
    }
    init();
    setInitStatus('initializing');
  }, [project, editor, viewport, initStatus, setInitStatus, addToast]);

  const handlePlay = useCallback(() => {
    if (editor.selectedTarget) {
      playerModalRef.current.open({target: editor.selectedTarget});
    }
  }, [editor]);

  const editScript = useCallback(() => {
    scriptModalRef.current.open();
  }, []);

  return (
    <div className="editor">
      <ScriptModal ref={scriptModalRef} />
      <div className="main">
        {initStatus === 'initializing' && (
	  <div className="loading-status">
	    <FontAwesomeIcon onClick={handlePlay} className="icon" icon="spinner" spin />
	    Loading project
	  </div>
	)}

	<ScenePanel />

	<div ref={viewportContainerRef} className="viewport">
	  <TransformToolbar/>

	  <div className="simulate-toolbar">
	    <FontAwesomeIcon onClick={handlePlay} className="icon" icon="play-circle" />
	  </div>

	  <div className="script-toolbar">
	    <div className="clickable script" onClick={editScript}>
	      <FontAwesomeIcon className="clickable" icon="edit" />
	      script
	    </div>
	  </div>
	</div>

	<div className="properties-panel">
	  <PropertiesPanel/>
	</div>

	<PlayerModal ref={playerModalRef} />
      </div>
    </div>
  )
}

export default Editor;
