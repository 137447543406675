import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import Payment from './Payment';
import Storage from './Storage';
import PaidProjects from './PaidProjects';
import Menu from './Menu';
import {useUser} from '../../contexts/user-context';

const Account = () => {
  const [selectedTab, setSelectedTab] = useState('storage');

  const {loadMe} = useUser();

  useEffect(() => {
    loadMe();
  }, [loadMe]);

  return (
    <div className="account">
      <Menu/>
      <div className="main">
        <div className="tabs">
    	  <div className={classnames({selected: selectedTab==='storage'}, "item")} onClick={() => setSelectedTab('storage')}>
    	    Storage
	  </div>
    	  <div className={classnames({selected: selectedTab==='paid-projects'}, "item")} onClick={() => setSelectedTab('paid-projects')}>
    	    Subscriptions
	  </div>
    	  <div className={classnames({selected: selectedTab==='payment'}, "item")} onClick={() => setSelectedTab('payment')}>
    	    Billing
	  </div>
	</div>

    	<div className="content">
    	  {selectedTab === 'storage' && <Storage />}
    	  {selectedTab === 'paid-projects' && <PaidProjects />}
    	  {selectedTab === 'payment' && <Payment />}
	</div>
      </div>
    </div>
  )
}

export default Account;
