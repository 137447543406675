import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles/common-menu.scss';
import './styles/main.scss';
import './styles/admin.scss';
import './styles/project.scss';
import './styles/asset-modal.scss';
import './styles/loading.scss';
import './styles/login.scss';
import './styles/editor.scss';
import './styles/dashboard.scss';
import './styles/account.scss';
import './font-awesome';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/xq-light.css';

import {ToastProvider} from 'react-toast-notifications';
import {AuthProvider} from './contexts/auth-context';
import {UserProvider} from './contexts/user-context';
import {AccountProvider} from './contexts/account-context';
import ApolloWrapper from './apollo-wrapper';

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <ToastProvider>
	<AuthProvider>
	  <ApolloWrapper>
	    <UserProvider>
  	      <AccountProvider>
		<App />
  	      </AccountProvider>
	    </UserProvider>
	  </ApolloWrapper>
	</AuthProvider>
      </ToastProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
