import {THREE} from 'pictarize-lib';

const resizeImage = (image, {targetSize}) => {
  return new Promise((resolve, reject) => {
    const width = parseInt(image.width > image.height? targetSize: targetSize * image.width/image.height);
    const height = parseInt(image.width > image.height? targetSize * image.height/image.width: targetSize);

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = width;
    canvas.height = height;

    context.drawImage(image, 0, 0, width, height);

    const newImage = new Image();
    newImage.onload = () => {
      //console.log("new image", newImage, newImage.width, newImage.height);
      resolve(newImage);
    }
    newImage.src = canvas.toDataURL();
  });
}

const generateModalPreview = (obj) => {
  const scene = new THREE.Scene();

  const width = 500;
  const height = 500;

  scene.add(obj);

  const color = 0xffffff;
  const intensity = 1;
  const light = new THREE.DirectionalLight( color, intensity );
  light.position.set( 2.5, 5, 3.8 );
  scene.add(light);

  const renderer = new THREE.WebGLRenderer({antialias: true, preserveDrawingBuffer: true});
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.setClearColor( 0xebeced );
  renderer.setPixelRatio( window.devicePixelRatio );
  renderer.setSize(width, height, false);

  const camera = new THREE.PerspectiveCamera(50, 1, 0.01, 1000);
  camera.position.set(0, 1, 2);
  camera.lookAt(new THREE.Vector3());
  camera.aspect = width / height;
  camera.updateProjectionMatrix();

  renderer.render(scene, camera);
  //return renderer.domElement;
  const newImage = new Image();
  newImage.src = renderer.domElement.toDataURL("image/png");
  return newImage;
}

const generateVideoPreview = (videoSrc) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.onloadeddata = () => {
      let capturing = false;
      video.addEventListener("timeupdate", () => {
	if (capturing) return;
	video.pause();
	capturing = true;

	const canvas = document.createElement('canvas');
	canvas.width = video.videoWidth;
	canvas.height = video.videoHeight;
	const context = canvas.getContext("2d");
	context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

	const newImage = new Image();
	newImage.onload = () => {
	  resolve(newImage);
	}
	newImage.src = canvas.toDataURL();
      });

      video.play().then(() => {
      }).catch((e) => {
	resolve(null);
      });
    }
    video.src = videoSrc;
  });
}

export {
  resizeImage,
  generateModalPreview,
  generateVideoPreview
}
