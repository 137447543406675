import React, {useCallback, useContext, useMemo} from 'react';
import { useApolloClient, useLazyQuery, gql } from '@apollo/client';
import {callMutation} from '../libs/graphql';

const AdminContext = React.createContext();

const GET_PROJECTS = gql`
  query AGetProjects($offset: Int, $limit: Int, $query: JSON) {
    aGetProjects(offset: $offset, limit: $limit, query: $query) {
      user {
        id
        email
      }
      project {
        id
        title
	builds
	modifiedAt
	createdAt
      }
      projectAnalytic {
        views
      }
    }
  }
`;

const CLONE_PROJECT = gql`
  mutation ACloneProject($projectId: ID!) {
    aCloneProject(projectId: $projectId) 
  }
`;

const UPDATE_PRO_MEMBER = gql`
  mutation AUpdateProMember($updateUserId: ID!, $expiredAt: Timestamp!) {
    aUpdateProMember(updateUserId: $updateUserId, expiredAt: $expiredAt) 
  }
`;

const AdminProvider = ({children}) => {
  const client = useApolloClient();

  const [loadProjects, {loading: projectsLoading, data: projectsData}] = useLazyQuery(GET_PROJECTS);

  const cloneProject = useCallback(async (projectId) => {
    const {error}  = await callMutation({
      client,
      mutation: CLONE_PROJECT,
      variables: {projectId: projectId}
    });
    return !error;
  }, [client]);

  const updateProMember = useCallback(async (updateUserId, expiredAt) => {
    const {error}  = await callMutation({
      client,
      mutation: UPDATE_PRO_MEMBER,
      variables: {updateUserId, expiredAt}
    });
    return !error;
  }, [client]);

  const projects = useMemo(() => {
    return (projectsData && projectsData.aGetProjects) || [];
  }, [projectsData]);

  const value = {
    projects,
    projectsLoading,
    loadProjects,
    cloneProject,
    updateProMember
  }

  return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
}

const useAdmin = () => useContext(AdminContext);

export {
  AdminProvider,
  useAdmin,
}
