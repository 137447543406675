import React, {useEffect, useRef, useCallback, useState} from 'react';
import { useHistory } from "react-router-dom";
import { useProject} from '../../contexts/project-context';
import { useEditor} from '../../contexts/editor-context';
import { useToasts } from 'react-toast-notifications';
import PublishModal from './PublishModal';
import SetttingsModal from './SettingsModal';
import EditableText from '../common/EditableText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Menu = () => {
  const publishModalRef = useRef(null);
  const settingsModalRef = useRef(null);
  const history = useHistory();
  const {project, saveProject} = useProject();
  const [hasUpdated, setHasUpdated] = useState(false);
  const {editor} = useEditor();
  const { addToast } = useToasts();

  const confirmEditTitle = useCallback(async (value) => {
    const {error} = await saveProject({title: value});
    if (error) {
      addToast('Failed to update title', { appearance: 'error', autoDismiss: true });
    } else {
      addToast('Project name saved', { appearance: 'success', autoDismiss: true });
    }
  }, [saveProject, addToast]);

  const publishClick = useCallback(async () => {
    if (editor.targets.length === 0) {
      addToast('At least one image target is required!', { appearance: 'error', autoDismiss: true });
      return;
    }
    publishModalRef.current.open();
  }, [addToast, editor]);

  const backClick = useCallback(() => {
    history.push("/dashboard");
  }, [history]);

  const openSettings = useCallback(() => {
    settingsModalRef.current.open();
  }, []);

  const saveHandler = useCallback(async () => {
    const targets = editor.exportData();
    const {error} = await saveProject({targets});
    if (error) {
      addToast('Failed to save project', { appearance: 'error', autoDismiss: true });
    } else {
      addToast('Project saved', { appearance: 'success', autoDismiss: true });
      setHasUpdated(false);
    }
  }, [editor, saveProject, setHasUpdated, addToast]);

  useEffect(() => {
    const listener = () => {
      setHasUpdated(true);
    }
    editor.signals.updated.add(listener);
    return () => {
      editor.signals.updated.remove(listener);
    }
  }, [setHasUpdated, editor])

  return (
    <div className="common-menu">
      <PublishModal ref={publishModalRef} saveHandler={saveHandler}/>
      <SetttingsModal ref={settingsModalRef} />

      <div className="left">
        <div className="breadcrumb">
	  <div className="link" onClick={backClick}>
	    Projects
	  </div>
	  <div>&gt;</div>
	  <EditableText originalValue={project.title} onConfirm={confirmEditTitle}/>

    	  <div>
	    <FontAwesomeIcon className="clickable" icon="wrench" onClick={openSettings}/>
	  </div>
	</div>
      </div>
      <div className="right">
        <div className="help">
	  <FontAwesomeIcon icon="lightbulb"/>
	  <a href="https://pictarize.com/guide/editor" target="_blank" rel="noreferrer">Help</a>
	</div>

	<button className="button primary" onClick={saveHandler} disabled={!hasUpdated}>Save</button>
	<button className="button primary" onClick={publishClick}>Publish...</button>
      </div>
    </div>
  )
}

export default Menu;
