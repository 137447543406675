import React, {useRef, useEffect, useState, useCallback, forwardRef} from 'react';
import Modal from '../common/Modal';
import TextInput from '../common/TextInput';
import getVideoId from 'get-video-id';
import YouTubePlayer from 'youtube-player';
import VimeoPlayer from '@vimeo/player';

const DEFAULT_WIDTH = 320;
const DEFAULT_HEIGHT = 180;

const AddEmbedModal = (props, ref) => {
  const playerRef = useRef(null);
  const youtubePreviewRef = useRef(null);
  const vimeoPreviewRef = useRef(null);
  const [url, setUrl] = useState("");
  const [videoMeta, setVideoMeta] = useState(null);

  const {addEmbedHandler} = props;

  const onOpen = useCallback(() => {
    setVideoMeta(null);
    setUrl("");
  }, [setVideoMeta]);

  useEffect(() => {
    if (!url) return;

    const videoMeta = getVideoId(url);

    if (videoMeta.service !== 'youtube' && videoMeta.service !== 'vimeo') {
      setVideoMeta(null);
      return;
    }
    if (!videoMeta.id) {
      setVideoMeta(null);
      return;
    }

    if (playerRef.current) {
      playerRef.current.destroy();
    }

    if (videoMeta.service === 'youtube') {
      const player = YouTubePlayer(youtubePreviewRef.current, {
	videoId: videoMeta.id,
	width: DEFAULT_WIDTH,
	height: DEFAULT_HEIGHT,
	playerVars: { autoplay: 0, controls: 0, fs: 0, playsinline: 1, rel: 0},
      });
      playerRef.current = player;
    }
    if (videoMeta.service === 'vimeo') {
      const player = new VimeoPlayer(vimeoPreviewRef.current, {
	id: videoMeta.id,
	width: DEFAULT_WIDTH,
      });
      playerRef.current = player;
    }

    setVideoMeta(videoMeta);
   }, [url, setVideoMeta]);

  const submit = useCallback(() => {
    addEmbedHandler({videoMeta});
  }, [videoMeta, addEmbedHandler]);


  return (
    <Modal {...props} ref={ref} className="add-embed-modal" header={"Add Content"} onOpen={onOpen}>
      <div className="title">youtube or vimeo URL</div>
      <TextInput type="text" placeholder="url" value={url} setValue={setUrl} icon="link" />

      <div className="embed-preview">
	<div ref={youtubePreviewRef}></div>
	<div ref={vimeoPreviewRef}></div>
      </div>

      {videoMeta && (
	<button className="primary button" onClick={submit}>Submit</button>
      )}
    </Modal>
  )
}

export default forwardRef(AddEmbedModal);
