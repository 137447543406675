import React, {useRef, forwardRef, useCallback} from 'react';
import { Chart, registerables } from 'chart.js';
import Modal from '../common/Modal';
import moment from 'moment';

Chart.register(...registerables);

const ChartModal = (props, ref) => {
  const containerRef = useRef(null);
  const chartRef = useRef(null);

  const buildChart = useCallback((views) => {
    const context = containerRef.current.getContext('2d');

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const dates = [];
    const from = moment().startOf('day').subtract(1, 'month'); 
    const to = moment().endOf('day');
    const current = from.clone();
    while (current.isBefore(to)) {
      dates.push(current.clone());
      current.add(1, 'day');
    }

    const viewsByDay = [];
    const labels = [];
    for (let i = 0; i < dates.length; i++) {
      const month = dates[i].format("MMM");
      const day = dates[i].format("DD");
      const label = [day];
      if (i > 0 && month !== dates[i-1].format("MMM")) {
	label.push(month);
      }
      labels[i] = label;
      viewsByDay[i] = 0;
    }

    Object.keys(views.dayCount).forEach((start) => {
      const index = moment(parseInt(start)).diff(from, 'day');
      viewsByDay[index] = views.dayCount[start];
    });

    chartRef.current = new Chart(context, {
      type: 'line',
      data: {
	labels,
	datasets: [{
	  label: '# Views for last 30 days',
	  backgroundColor: '#6699b2',
	  borderColor: '#6699b2',
	  data: viewsByDay,
	}]
      }
    });

  }, []);

  const onOpen = useCallback((params) => {
    buildChart(params.analytic.views);
  }, [buildChart]);

  return (
    <Modal className="chart-modal" {...props} ref={ref} header={"Views"} onOpen={onOpen}>
      <canvas className="chart" ref={containerRef} />
    </Modal>
  )
}

export default forwardRef(ChartModal)
