import {TextTexture, constants, utils, fileLoader, THREE } from 'pictarize-lib';
import {getThumbnailURL} from '../../libs/video-thumbnail';
import {generateVideoPreview} from '../../libs/image';
const {loadVideo, loadImageAsChromaMesh, loadImageFromURL, loadImageAsSpriteMesh, loadBlobFromURL, loadImageAsMesh, loadImage, loadGLBModel} = fileLoader;

class Target {
  constructor() {
    this.uuid = THREE.MathUtils.generateUUID();
    this.name = "Target";
    this.subScene = new THREE.Group();
    this.cssSubscene = new THREE.Group();
    this.imageTarget = null;
    this.contents = [];
    this.script = '';
  }

  dispose() {
    this._removeImageTarget();
    for (let i = this.contents.length - 1; i >= 0; i--) {
      this._removeContent(this.contents[i]);
    }
  }

  removeContent(content) {
    this._removeContent(content);
  }

  async copyContent(content) {
    const newProperties = Object.assign({}, content.properties);
    newProperties.position = [content.properties.position[0]+20, content.properties.position[1]+20, content.properties.position[2]+20];
    if (content.type === 'asset') {
      return await this.addAssetContent(content.asset, newProperties);
    } else if (content.type === 'embed') {
      return await this.addEmbedContent(content.embed, newProperties);
    } else if (content.type === 'text') {
      return await this.addTextContent(content.text, newProperties);
    }
  }

  updateName(name) {
    this.name = name;
  }

  updateContentName(content, name) {
    content.name = name; 
  }

  updateContentProperty(content, property, value) {
    if (property === 'position') {
      content.mesh.position.copy(value);
    } else if (property === 'rotation') {
      content.mesh.rotation.copy(value);
    } else if (property === 'scale') {
      content.mesh.scale.copy(value);
    } else if (property === 'userFacing') {
      content.properties[property] = value;
      content.mesh.children.forEach((c) => {
	c.visible = (c.userData.spriteMode === true) !== value;
      });
    } else if (property === 'videoChroma') {
      content.properties[property] = value;
      content.mesh.children.forEach((c) => {
	c.visible = (c.userData.chromaMode === true) !== value;
      });
    } else {
      content.properties[property] = value;
    }
    content.mesh.updateMatrixWorld(true);
  }

  updateContentText(content, text) {
    const texture = content.mesh.children[0].material.map;
    Object.assign(texture, text);
    texture.redraw();
    content.mesh.scale.set(texture.width, texture.height, 1);
    content.properties.scale = [content.mesh.scale.x, content.mesh.scale.y, content.mesh.scale.z];
    content.text = text;
  }

  async setImageTarget(asset) {
    const blob = await loadBlobFromURL(asset.publicPath);
    const mesh = await loadImageAsMesh(blob);
    mesh.scale.set(mesh.scale.x * constants.DEFAULT_WIDTH, mesh.scale.y * constants.DEFAULT_WIDTH, 1);
    mesh.rotation.x = -Math.PI / 2;
    const image = await loadImage(blob);

    // remove existing one
    this._removeImageTarget();

    this.subScene.add(mesh);
    mesh.position.fromArray([0, -1, 0]);
    this.imageTarget = {
      isTarget: true,
      name: asset.name,
      asset,
      image,
      mesh
    }
  }

  async addEmbedContent(embed, properties) {
    const {videoMeta} = embed;
    const aspect = constants.DEFAULT_EMBED_WIDTH/constants.DEFAULT_EMBED_HEIGHT;

    const previewURL = await getThumbnailURL(videoMeta);
    const blob = await loadBlobFromURL(previewURL);
    const normalMesh = await loadImageAsMesh(blob, aspect);
    const spriteMesh = await loadImageAsSpriteMesh(blob, aspect);
    normalMesh.userData.spriteMode = false;
    normalMesh.userData.spriteMode = true;
    const mesh = new THREE.Group();
    mesh.userData.aspect = aspect;
    mesh.add(normalMesh);
    mesh.add(spriteMesh);

    if (!properties) {
      properties = {
	position: [0, 0, 5],
	rotation: [-Math.PI/2, 0, 0],
	scale: [constants.DEFAULT_EMBED_WIDTH, constants.DEFAULT_EMBED_WIDTH, 1],
	userFacing: false,
	videoAutostart: true,
	videoClickToggle: true,
      }
    }
    spriteMesh.visible = !!properties.userFacing;
    normalMesh.visible = !properties.userFacing;
    const content = this._addContent({type: 'embed', mesh, name: 'video', embed, properties});
    return content;
  }

  async addAssetContent(asset, properties) {
    const blob = await loadBlobFromURL(asset.publicPath);
    let mesh = null;
    if (asset.type === 'image') {
      const normalMesh = await loadImageAsMesh(blob);
      const spriteMesh = await loadImageAsSpriteMesh(blob);
      normalMesh.userData.spriteMode = false;
      normalMesh.userData.spriteMode = true;
      mesh = new THREE.Group();
      mesh.add(normalMesh);
      mesh.add(spriteMesh);
      if (!properties) {
	properties = {
	  position: [0, 0, 0],
	  rotation: [-Math.PI/2, 0, 0],
	  scale: [constants.DEFAULT_WIDTH, constants.DEFAULT_WIDTH, 1],
	  userFacing: false,
	}
      }
      spriteMesh.visible = !!properties.userFacing;
      normalMesh.visible = !properties.userFacing;
    } else if (asset.type === 'glb') {
      mesh = await loadGLBModel(blob, constants.DEFAULT_WIDTH);
      if (!properties) {
      	const box = new THREE.Box3().setFromObject(mesh);
	const maxSize = Math.max(box.max.x - box.min.x, box.max.y - box.min.y);
	const scale = constants.DEFAULT_WIDTH / maxSize;
	properties = {
	  position: [0, 0, 0],
	  rotation: [0, 0, 0],
	  scale: [scale, scale, scale],
	  animateAutostart: true,
	  animateLoop: 'repeat',
	}
      }
    } else if (asset.type === 'audio') {
      // create an object for the sound to play from
      const sphere = new THREE.SphereGeometry( 50, 32, 16 );
      const material = new THREE.MeshPhongMaterial( { color: 0x6699b2, opacity: 0.3, transparent: true } );
      mesh = new THREE.Mesh( sphere, material );
      if (!properties) {
	properties = {
	  position: [0, 0, 0],
	  rotation: [0, 0, 0],
	  scale: [1, 1, 1],
	  audioLoop: true,
	  audioAutostart: true,
	}
      }
    } else if (asset.type === 'video') {
      const video = await loadVideo(blob);

      // if no user action, video cannot play and cannot generate preview
      let img = await generateVideoPreview(video.src);
      if (!img) {
        img = await loadImageFromURL(asset.thumbnail);
      }
      const normalMesh = await loadImageAsMesh(img, 1);
      const chromaMesh = await loadImageAsChromaMesh(img, 1, 0xd432);
      normalMesh.userData.chromaMode = false;
      normalMesh.userData.chromaMode = true;
      mesh = new THREE.Group();
      mesh.add(normalMesh);
      mesh.add(chromaMesh);

      if (!properties) {
	properties = {
	  position: [0, 0, 0],
	  rotation: [-Math.PI/2, 0, 0],
	  scale: [constants.DEFAULT_WIDTH, constants.DEFAULT_WIDTH * img.height / img.width, 1],
	  videoChroma: false,
	  videoLoop: true,
	  videoAutostart: true,
	  videoClickToggle: true,
	}
      }
      chromaMesh.visible = !!properties.videoChroma;
      normalMesh.visible = !properties.videoChroma;
    }
    return this._addContent({type: 'asset', asset, name: asset.name, mesh, properties});
  }

  addTextContent(text, properties) {
    const {spriteMesh, normalMesh} = this._createTextMesh(text, false);
    normalMesh.userData.spriteMode = false;
    normalMesh.userData.spriteMode = true;
    const mesh = new THREE.Group();
    mesh.add(normalMesh);
    mesh.add(spriteMesh);
    const texture = normalMesh.material.map;
    mesh.scale.set(texture.width, texture.height, 1);

    if (!properties) {
      properties = {
	position: [0, 0, 0],
	rotation: [0, 0, 0],
	scale: [mesh.scale.x, mesh.scale.y, mesh.scale.z],
	userFacing: false,
      }
    }
    spriteMesh.visible = !!properties.userFacing;
    normalMesh.visible = !properties.userFacing;
    const content = this._addContent({type: 'text', mesh, name: 'text', properties, text: text});
    return content;
  }

  _createTextMesh(text, isSprite) {
    const options = {
      alignment: text.alignment,
      color: text.color,
      backgroundColor: text.backgroundColor,
      fontFamily: text.fontFamily,
      fontSize: text.fontSize,
      fontWeight: text.fontWeight,
      fontStyle: text.fontStyle,
      text: text.text
    }
    const texture = new TextTexture(options);

    const spriteMaterial = new THREE.SpriteMaterial();
    spriteMaterial.map = texture;
    const spriteMesh = new THREE.Sprite( spriteMaterial );
    const normalMaterial = new THREE.MeshStandardMaterial();
    const geometry = new THREE.PlaneGeometry(1, 1);
    normalMaterial.map = texture;
    normalMaterial.side = THREE.DoubleSide;
    const normalMesh = new THREE.Mesh( geometry, normalMaterial );

    texture.redraw();

    return {spriteMesh, normalMesh}
  }

  _addContent({type, name, mesh, properties, asset, embed, text}) {
    const content = {
      uuid: THREE.MathUtils.generateUUID(),
      isContent: true,
      type,
      name,
      mesh,
      asset,
      embed,
      text,
      properties
    }

    const {scale, rotation, position} = properties;
    mesh.scale.fromArray(scale);
    mesh.rotation.fromArray(rotation);
    mesh.position.fromArray(position);

    this.subScene.add(mesh);
    this.contents.push(content);
    return content;
  }

  _removeImageTarget() {
    if (this.imageTarget !== null) {
      this.subScene.remove(this.imageTarget.mesh);
      utils.disposeObject(this.imageTarget.mesh);
    }
  }

  _removeContent(content) {
    this.contents = this.contents.filter((c) => c !== content);
    this.subScene.remove(content.mesh);
    utils.disposeObject(content.mesh);
  }

  async importData(targetData) {
    const {imageTarget, contents} = targetData;

    this.name = targetData.name;
    this.script = targetData.script;
    await this.setImageTarget(imageTarget.asset);

    let missing = 0;
    for (const contentData of contents) {
      try {
	let content;
	if (contentData.type === 'asset') {
	  content = await this.addAssetContent(contentData.asset, Object.assign({}, contentData.properties));
	} else if (contentData.type === 'embed') {
	  content = await this.addEmbedContent(contentData.embed, Object.assign({}, contentData.properties));
	} else if (contentData.type === 'text') {
	  content = await this.addTextContent(contentData.text, Object.assign({}, contentData.properties));
	}
	content.name = contentData.name;
	content.script = contentData.script;
      } catch (e) {
	console.log("missing ", e);
	missing += 1;
      }
    }
    return missing;
  }

  exportData () {
    const imageTarget = {
      asset: {
	id: this.imageTarget.asset.id,
	name: this.imageTarget.asset.name,
	publicPath: this.imageTarget.asset.publicPath,
	size: this.imageTarget.asset.size,
      }
    }
    const contents = this.contents.map((content) => {
      const {asset, embed, text, mesh, name, type, properties, script} = content;

      // sync mesh properties 
      properties.position = mesh.position.toArray();
      properties.rotation = mesh.rotation.toArray();
      properties.scale = mesh.scale.toArray();

      const base = {
	name, 
	type,
	properties,
	script
      }

      if (type === 'asset') {
	base.asset = {
	  id: asset.id,
	  name: asset.name,
	  publicPath: asset.publicPath,
	  type: asset.type,
	  size: asset.size,
	}
	if (asset.type === 'video') {
	  base.asset.thumbnail = asset.thumbnail;
	}
      } else if (type === 'embed') {
	base.embed = embed
      } else if (type === 'text') {
	base.text = text;
      }
      return base;
    });
    return {
      name: this.name,
      script: this.script,
      imageTarget,
      contents
    }
  }
}

export default Target;
