import React, {useState, useCallback, forwardRef} from 'react';
import {useEditor} from '../../contexts/editor-context';
import {placeholderScript} from '../../libs/editor/defaultScripts';
import Modal from '../common/Modal';
import {Controlled as CodeMirror} from 'react-codemirror2-react-17'

const ScriptModal = (props, ref) => {
  const [code, setCode] = useState("");
  const {editor} = useEditor();

  const onClose = useCallback(() => {
  }, []);

  const onOpen = useCallback(() => {
    if (editor.selectedTarget.script) {
      setCode(editor.selectedTarget.script);
    } else {
      setCode(placeholderScript);
    }
  }, [setCode, editor]);

  const handleCancel = useCallback(() => {
    ref.current.close();
  }, [ref]);

  const handleSave = useCallback(() => {
    editor.updateTargetScript(editor.selectedTarget, code);
    ref.current.close();
  }, [code, editor, ref]);

  return (
    <Modal {...props} ref={ref} className="script-modal" header={"Edit Script"} onOpen={onOpen} onClose={onClose}>
      <div>
	<CodeMirror
	  value={code}
	  options={{
	    mode: 'javascript',
	    theme: 'xq-light',
	    lineNumbers: true
	  }}
    	  onBeforeChange={(editor, data, value) => {
	    setCode(value);
	  }}
	  onChange={(editor, data, value) => {
	  }}
	/>
        <div className="buttons">
    	  <button className="primary button" onClick={handleSave}>Update</button>
    	  <button className="negative button" onClick={handleCancel}>Cancel</button>
	</div>
      </div>
    </Modal>
  )
}

export default forwardRef(ScriptModal);
